<template>
  <section>
    <b-sidebar :id="'sidebar-fuel-' + stepProvider.id" right backdrop shadow lasy no-header>
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t('common.fuel_certificate') }}
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <b-form class="p-1">
          <div class="form-label-group">
            <b-form-input v-model="fuelCertificate.certificateNumber" :placeholder="$t('fuel_certificate.certificate_number')" />
            <label>{{ $t('fuel_certificate.certificate_number') }}</label>
          </div>
          <div class="form-label-group">
            <b-form-datepicker class="mb-1" :local="$i18n.locale" :placeholder="$t('fuel_certificate.certificate_number')" />
            <label>{{ $t('fuel_certificate.certificate_number') }}</label>
          </div>
          <b-form-group class="mb-0">
            <label>{{ $t('timeline.start_time_of_fueling') }}</label>
            <flat-pickr
              v-model="fuelCertificate.startFuelling"
              :placeholder="$t('timeline.start_time_of_fueling')"
              class="form-control"
              :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true }"
            />
          </b-form-group>
          <b-form-group class="mb-2">
            <label>{{ $t('timeline.end_time_of_fueling') }}</label>
            <flat-pickr
              v-model="fuelCertificate.startFuelling"
              :placeholder="$t('timeline.end_time_of_fueling')"
              class="form-control"
              :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', localize: $i18n.locale, time_24hr: true }"
            />
          </b-form-group>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.aircraftRegistration" :placeholder="$t('fuel_certificate.aircraft_registration')" />
            <label>{{ $t('fuel_certificate.aircraft_registration') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.fuelTruckNumber" :placeholder="$t('fuel_certificate.fuel_truck_number')" />
            <label>{{ $t('fuel_certificate.fuel_truck_number') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.fuelType" :placeholder="$t('fuel_certificate.fuel_type')" />
            <label>{{ $t('fuel_certificate.fuel_type') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.fuelAmount" :placeholder="$t('fuel_certificate.fuel_amount')" />
            <label>{{ $t('fuel_certificate.fuel_amount') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.fuelDensity" :placeholder="$t('fuel_certificate.fuel_density')" />
            <label>{{ $t('fuel_certificate.fuel_density') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.fuelTemperature" :placeholder="$t('fuel_certificate.fuel_temperature')" />
            <label>{{ $t('fuel_certificate.fuel_temperature') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.fuelNet" :placeholder="$t('fuel_certificate.fuel_net')" />
            <label>{{ $t('fuel_certificate.fuel_net') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.fuelTax" :placeholder="$t('fuel_certificate.fuel_taxes')" />
            <label>{{ $t('fuel_certificate.fuel_taxes') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.invoiceNumber" :placeholder="$t('fuel_certificate.invoice_number')" />
            <label>{{ $t('fuel_certificate.invoice_number') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.provider" :placeholder="$t('fuel_certificate.provider')" />
            <label>{{ $t('fuel_certificate.provider') }}</label>
          </div>
          <div class="form-label-group mb-2">
            <b-form-input v-model="fuelCertificate.client" :placeholder="$t('fuel_certificate.client')" />
            <label>{{ $t('fuel_certificate.client') }}</label>
          </div>
          <div class="d-flex p-2 mt-2 justify-content-end">
            <b-button
              size="sm"
              class="w-100"
              @click="
                hide()
                sendFormSuccess()
              "
            >
              {{ $t('timeline.fuel_certificate.add') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hide">
              <font-awesome-icon icon="ban" class="mr-50" />
              {{ $t('action.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'SidebarFuelCertificate',

  components: {
    flatPickr,
  },
  props: {
    stepProvider: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fuelCertificate: {
        certificateNumber: '',
        certificateDate: '',
        startFuelling: '',
        endFuelling: '',
        aircraftRegistration: '',
        fuelTruckNumber: '',
        fuelType: '',
        fuelAmount: null,
        fuelDensity: null,
        fuelTemperature: null,
        fuelNet: null,
        fuelTax: null,
        invoiceNumber: '',
        provider: '',
        client: '',
      },
    }
  },
  methods: {
    sendFormSuccess() {
      this.alert('api.alert.form.saved_successfully')
    },
  },
}
</script>
