<template>
  <b-sidebar id="sidebar-addGendec" :visible="isAddGendecSidebarActive" lasy no-header @change="val => $emit('update:is-add-gendec-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('trip.gendec.title') }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <b-overlay :show="lockSubmit" no-fade>
        <validation-observer ref="addGendecSubmitRules" #default="{ handleSubmit }">
          <b-form class="px-2" @submit.prevent="handleSubmit(onSubmit)">
            <!-- Gendec Documents selector-->

            <b-row class="mb-1">
              <b-col lg="12">
                <b-form-group class="mt-1">
                  <label label-for="gendecDropzone">{{ $t('trip.gendec.choose_gendec') }}</label>
                  <div class="text-muted font-small-3">
                    <span class="font-weight-bold">{{ $t('dropzone.accepted_format') }}:</span> <span>pdf</span> |
                    <span class="font-weight-bold">{{ $t('dropzone.max_file_size') }}:</span> <span>500Ko</span>
                  </div>
                  <vue-dropzone
                    id="gendecDropzone"
                    ref="addGendecDropzone"
                    :options="dropzoneGendec"
                    class="d-flex justify-content-center align-content-center dropzone_small"
                    @vdropzone-removed-file="removeFile($event)"
                    @vdropzone-file-added="sendFile($event)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Vendor selector-->
            <b-row>
              <b-col cols="12">
                <b-form-group :label="$tc('trip.gendec.select_vendor_title', 0)" label-for="gendecVendor">
                  <v-select
                    id="gendecVendor"
                    ref="gendecVendorSelector"
                    v-model="organizationIds"
                    :options="tripStepProviders"
                    :placeholder="$t('trip.gendec.select_vendor')"
                    :reduce="tripStepProviders => tripStepProviders.organizationId"
                    class="select-size-sm"
                    label="name"
                    multiple
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Message-->
            <b-row class="mb-2">
              <b-col cols="12">
                <app-form-text-area-itn
                  ref="messageArea"
                  v-model="message"
                  :default-lang="$store.state.auth.defaultLang"
                  :label="$t('trip.gendec.message')"
                  :languages="$store.state.auth.activelang"
                  :max-rows="5"
                  :rows="5"
                  form-name="offer-informations-light"
                  no-resize
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>

      <!--Footer-->
      <div class="d-flex pr-2 justify-content-end">
        <b-button :disabled="lockSubmit" class="mr-2" type="submit" @click.prevent="validationForm">
          <font-awesome-icon class="mr-50" icon="fa-solid fa-paper-plane" />
          {{ $t('action.send') }}
        </b-button>
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" :disabled="lockSubmit" variant="outline-secondary" @click="hide">
          <font-awesome-icon class="mr-50" icon="ban" />
          {{ $t('action.cancel') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>
<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import AppDropZone from '@/components/AppDropZone.vue'
import { patchTripStepEventController } from '@/request/globalApi/requests/tripStepRequests'
import imageUpload from '@/helpers/imgUpload'
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'
import AppFormTextAreaItn from '@/components/AppFormTextAreaItn.vue'
import { fetchTripStepProvidersRequest } from '@/request/globalApi/requests/tripStepEventRequests'

export default {
  name: 'SidebarAddGendec',

  components: {
    vueDropzone: AppDropZone,
    AppFormTextAreaItn,
  },

  mixins: [imageUpload],
  model: {
    prop: 'isAddGendecSidebarActive',
    event: 'update:is-add-gendec-sidebar-active',
  },
  props: {
    isAddGendecSidebarActive: {
      type: Boolean,
      required: true,
    },
    languages: {
      type: Array,
      default: () => [],
    },
    stepEvent: {
      type: Object,
      default: () => ({}),
    },
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tripStepProviders: [],
      dropzoneGendec: {
        url: 'https://localhost',
        dictDefaultMessage: this.$t('trip.gendec.upload_doc'),
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: '.pdf',
        addRemoveLinks: true,
        uploadMultiple: true,
        parallelUploads: 100,
        maxFiles: 100,
      },
      gendecFiles: [],
      savedFile: {},
      organizationIds: [],
      message: {
        en: '',
        fr: '',
      },
      lockSubmit: false,
    }
  },
  mounted() {
    this.APIFetchTrip()
    this.getProvidersOrganization()
  },

  methods: {
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)
      try {
        this.trip = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })

        this.trip.tripSteps.forEach(tripStep => {
          if (!this.getCurrentTripStep(tripStep.flightDeparture)) {
            this.getCurrentTripStep(tripStep.flightArrival)
          }
        })
      } catch (error) {
        console.warn(error)
      }
    },
    getProvidersOrganization() {
      fetchTripStepProvidersRequest(this.stepEvent.id).then(r => {
        this.tripStepProviders = r.data
      })
    },
    getCurrentTripStep(leg) {
      let currentTripStep = null
      Object.entries(leg).forEach(([key, val]) => {
        if (key === 'id' && val === Number(this.$route.params.event_id)) {
          currentTripStep = leg
          this.leg = currentTripStep
          return true
        }
        return false
      })
    },
    removeFile(fileRemoved) {
      this.gendecFiles.forEach((file, index) => {
        if (file.uuid === undefined || file.uuid.includes(fileRemoved.upload.uuid)) {
          this.gendecFiles.splice(index, 1)
        }
      })
    },
    sendFile(file) {
      const formData = new FormData()
      formData.append('temporary_file', file)

      postFileRequest(formData)
        .then(response => {
          this.savedFile = {}
          this.savedFile.fileId = response.data.id
          this.savedFile.deleteOldFile = false
          this.savedFile.uuid = file.upload.uuid
          this.savedFile.id = null
        })
        .finally(() => {
          this.gendecFiles.push(this.savedFile)
        })
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.addGendecSubmitRules) {
          this.$refs.addGendecSubmitRules.reset()
          this.$refs.addGendecDropzone.removeAllFiles()
          this.gendecFiles = []
          this.organizationIds = []
          // TODO: dynamic lang init
          this.message.fr = ''
          this.message.en = ''
        }
      })
    },
    validationForm() {
      this.$refs.addGendecSubmitRules.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
    onSubmit() {
      this.lockSubmit = true
      this.gendecFiles.forEach(file => {
        delete file.uuid
      })
      const gendecObj = {
        gendecFiles: this.gendecFiles,
        organizationIds: this.organizationIds,
        message: this.message,
      }
      patchTripStepEventController(this.stepTrip.id, gendecObj)
        .then(() => {
          this.resetValidator()
          this.$emit('refetch-data')
          this.$emit('update:is-add-gendec-sidebar-active', false)
        })
        .finally(() => {
          this.lockSubmit = false
        })
    },
  },
}
</script>
