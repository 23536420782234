import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/trip-step'

export const fetchTripStepParticipantsRequest = tripStepId => globalApi.get(`/${END_POINT}/${tripStepId}/participant`)

export const postTripStepParticipantRequest = (tripStepId, participant, message) => globalApi.post(`/${END_POINT}/${tripStepId}/participant`, participant, { message })

export const editTripStepParticipantsRequest = (tripStepId, participants, message) => globalApi.patch(`/${END_POINT}/${tripStepId}/participant`, participants, { message })

export const fetchTripStepParticipantRequest = (tripId, participantId) => globalApi.get(`/${END_POINT}/${participantId}/participant/${participantId}`)

export const editTripStepParticipantRequest = (tripId, participant, message) => globalApi.patch(`/${END_POINT}/${tripId}/disabled-participant/${participant.id}`, participant, { message })

export const deleteTripStepParticipantRequest = (tripId, participantId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`/${END_POINT}/${tripId}/participant/${participantId}`, { message }),
)
