<template>
  <span v-if="localText">{{ localText }}</span>
  <span v-else class="font-italic font-small-3">{{ $t('common.not_specified') }}</span>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'AppNotSpecifiedDefault',

  props: {
    text: {
      type: [String, Number, Boolean],
      default: null,
    },
  },

  setup(props, ctx) {
    const { $i18n } = ctx.root

    const localText = computed(() => {
      if (['string', 'number'].includes(typeof props.text)) return props.text

      switch (props.text) {
        case true: return $i18n.t('common.yes')
        case false: return $i18n.t('common.no')
        default: return null
      }
    })

    return {
      localText,
    }
  },
})
</script>
