<template>
  <b-sidebar id="sidebar-submitSlot" :visible="value" lasy no-header @change="$emit('input', $event)">
    <template #default="{ hide }">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 ppr-no-print">
          {{ $t('trip.event.slot.submit') }} - {{
            stepType === 'departure' ? $t('trip.departure') : $t('trip.arrival')
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer ppr-no-print" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <b-card-body v-show="!pprActive">
        <validation-observer ref="addSlotRules" #default="{ handleSubmit }">
          <!--SUBJECT-->
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <div class="d-flex mb-1 justify-content-between align-items-center">
              <!--CALENDAR-->
              <b-card-sub-title>
                <span>{{ $t('trip.event.slot.ppr_date') }}:</span>
                <span> {{ $moment.utc(stepEvent.pprRequestDate).format('ddd, MMM Do YYYY HH:mm') }} {{
                  $t('common.utc')
                }}</span>
                <br>
                <span>{{ $t('common.airport') }}:</span>
                <span v-if="stepEvent.airport"> {{ stepEvent.airport.name }} - {{ stepEvent.airport.icaoCode }}</span>
              </b-card-sub-title>

              <!-- PPR -->
              <TimelineStepFlightPpr
                v-if="stepEvent.allowedToSeePpr"
                :step-event="stepEvent"
                :step-trip="stepTrip"
                :step-type="stepType"
              />
            </div>
            <div class="app-calendar">
              <!-- Calendar -->
              <b-form-group :label="$t('trip.validate_airport.submit_slot.slot_number')" label-for="slot-number">
                <app-input id="slot-number" v-model="slotNumber" name="slot-number" />
              </b-form-group>

              <b-form-group :label="`${$t('trip.validate_airport.slot.date')} ${$t('common.utc')}`">
                <flat-pickr
                  v-model="dateSlotSelected"
                  :config="{
                    altInput: true,
                    altFormat: 'D, F J Y',
                    dateFormat: 'Y-m-d',
                    localize: $i18n.locale,
                  }"
                  class="form-control"
                />
              </b-form-group>

              <b-form-group :label="`${$t('trip.validate_airport.slot.start_hour')} ${$t('common.utc')}`">
                <flat-pickr
                  v-model="timeSlotSelected"
                  :config="{
                    enableTime: true,
                    allowInput: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    localize: $i18n.locale,
                    time_24hr: true,
                  }"
                  class="form-control"
                />
                <p v-if="timeSlotSelected">
                  {{ $t('common.local_time') }} :
                  {{
                    $moment
                      .utc(dateSlotSelected + ' ' + timeSlotSelected)
                      .utcOffset(stepEvent.airport.timeZone)
                      .format('ddd, MMM Do YYYY HH:mm')
                  }}
                </p>
              </b-form-group>

              <b-form-group :label="`${$t('trip.validate_airport.slot.end_hour')} ${$t('common.utc')}`">
                <flat-pickr
                  v-model="endTimeSlotSelected"
                  :config="{
                    enableTime: true,
                    allowInput: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    localize: $i18n.locale,
                    time_24hr: true,
                  }"
                  class="form-control"
                />
                <p v-if="endTimeSlotSelected">
                  {{ $t('common.local_time') }} :
                  {{
                    $moment
                      .utc(dateSlotSelected + ' ' + endTimeSlotSelected)
                      .utcOffset(stepEvent.airport.timeZone)
                      .format('ddd, MMM Do YYYY HH:mm')
                  }}
                </p>
              </b-form-group>

              <b-form-group
                :label="$t('trip.validate_airport.submit_slot.slot_comment')"
                class="mt-2"
                label-for="slot_comment"
              >
                <b-form-textarea id="slot_comment" v-model="slotComment" name="slot-number" />
              </b-form-group>
              <b-form-group :label="$t('trip.validate_airport.submit_slot.slot_file')" label-for="slot-number">
                <div class="text-muted font-small-3">
                  <span class="font-weight-bold">{{ $t('dropzone.accepted_format') }}:</span>
                  <span>png, jpeg, pdf</span> |
                  <span class="font-weight-bold">{{ $t('dropzone.max_file_size') }}:</span> <span>500Ko</span>
                </div>
                <vue-dropzone
                  id="submitSlotFile"
                  ref="submitSlotFile"
                  :options="dropzoneFile"
                  class="d-flex justify-content-center align-content-center dropzone_small"
                  @vdropzone-mounted="dropzoneMounted"
                  @vdropzone-removed-file="removeFile($event)"
                  @vdropzone-file-added="sendFile($event)"
                />
              </b-form-group>
            </div>
            <b-overlay :show="slotsLoading">
              <full-calendar
                v-if="calendarOptions.events.length > 0 && !slotsLoading"
                id="submitSlotCalendar"
                ref="calendarSlot"
                :options="calendarOptions"
                :plugins="calendarOptions.plugins"
                class="full-calendar"
              />
            </b-overlay>
            <!-- FOOTER -->
            <div class="d-flex justify-content-end mt-2">
              <b-button :disabled="slotLoading || !$can('TRIP_SLOT_MANAGEMENT_EDIT')" class="mr-2" type="submit">
                <font-awesome-icon class="mr-50" icon="calendar-check" />
                {{ $t('trip.event.slot.submit') }}
              </b-button>

              <b-button variant="outline-secondary" @click="hide">
                <font-awesome-icon class="mr-50" icon="ban" />
                {{ $t('action.cancel') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
      <b-overlay :show="pprLoading">
        <b-card-body v-show="pprActive">
          <!--PPR-->
          <div class="d-flex justify-content-between align-items-center">
            <feather-icon
              class="ml-1 cursor-pointer ppr-no-print"
              icon="ArrowLeftIcon"
              size="16"
              @click.prevent="pprView()"
            />
            <h5 class="mb-0">{{ $t('trip.event.slot.ppr') }}</h5>
            <feather-icon
              class="ml-1 cursor-pointer ppr-no-print"
              icon="PrinterIcon"
              size="16"
              @click.prevent="pprPrint()"
            />
          </div>
          <div class="ppr_infos">
            <div class="info">
              <strong>{{ $t('trip.event.slot.ppr_date') }} : </strong>
              <span v-if="stepType === 'departure'">{{
                $moment.utc(ppr.flightArrival.pprRequestDate).format('ddd, MMM Do YYYY HH:mm')
              }} {{ $t('common.utc') }}</span>
              <span v-else>{{
                $moment.utc(ppr.flightDeparture.pprRequestDate).format('ddd, MMM Do YYYY HH:mm')
              }} {{ $t('common.utc') }}</span>
            </div>

            <div class="info">
              <strong>
                {{ $t('common.airport') }}:
              </strong>
              <span v-if="stepEvent.airport"> {{ stepEvent.airport.name }} - {{ stepEvent.airport.icaoCode }}</span>
            </div>

            <div class="specific">
              <strong>
                {{ $t('trip.event.movement') }}:
              </strong>
              {{ stepType === 'departure' ? $t('trip.departure') : $t('trip.arrival') }}
            </div>

            <div class="info">
              <strong>{{ $t('common.date') }} : </strong>
              <span v-if="stepEvent.slotStartDate">{{
                $moment.utc(stepEvent.slotStartDate).format('ddd, MMM Do YYYY')
              }} {{ $t('common.utc') }}</span>
            </div>

            <div class="info">
              <strong>{{ $t('trip.event.slot.ppr_requested_slot') }} : </strong>
              <span>{{
                $moment.utc(stepEvent.startsAt).format('HH:mm')
              }} - {{ $moment.utc(stepEvent.endsAt).format('HH:mm') }} {{ $t('common.utc') }}</span>
            </div>

            <div class="specific">
              <strong>{{ $t('aircraft.call_sign') }} : </strong><span
                v-if="ppr.trip.organizationAircraft.callSign"
              >{{ ppr.trip.organizationAircraft.callSign }}</span>
            </div>

            <div class="info">
              <strong>{{ $t('aircraft.registration') }} : </strong><span
                v-if="ppr.trip.organizationAircraft.registration"
              >{{
                ppr.trip.organizationAircraft.registration
              }}</span>
            </div>

            <div class="info">
              <strong>{{ $t('airport.icao') }} : </strong><span
                v-if="ppr.trip.organizationAircraft"
              >{{ ppr.trip.organizationAircraft.type }}</span>
            </div>

            <div class="info">
              <strong>{{ $t('trip.event.slot.ppr_aircraft') }} : </strong><span>{{
                ppr.trip.organizationAircraft && ppr.trip.organizationAircraft.homeBase ? ppr.trip.organizationAircraft.homeBase.name : ''
              }}</span>
            </div>

            <div v-if="stepType === 'departure'" class="specific">
              <div class="info">
                <strong class="text-capitalize">{{ $t('common.to') }} : </strong><span>{{
                  ppr.flightArrival.airport.name
                }} - {{ ppr.flightArrival.airport.icaoCode }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.eta') }}
                  : </strong><span>{{ $moment.utc(ppr.flightArrival.eventDateTime).format('ddd, MMM Do YYYY HH:mm') }} {{
                  $t('common.utc')
                }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.slot.ppr_pax') }} : </strong><span>{{ ppr.passengers }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.slot.ppr_crew') }} : </strong><span>{{ ppr.crew }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.flight_plan.number') }} : </strong><span>{{ ppr.flightPlanNumber }}</span>
              </div>
            </div>

            <div v-else class="specific">
              <div class="info">
                <strong class="text-capitalize">{{ $t('common.from') }}
                  : </strong><span>{{ ppr.flightDeparture.airport.name }} - {{
                  ppr.flightDeparture.airport.icaoCode
                }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.etd') }}
                  : </strong><span>{{ $moment.utc(ppr.flightDeparture.eventDateTime).format('ddd, MMM Do YYYY h:mm') }} {{
                  $t('common.utc')
                }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.slot.ppr_pax') }} : </strong><span>{{ ppr.passengers }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.slot.ppr_crew') }} : </strong><span>{{ ppr.crew }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.flight_plan.number') }} : </strong><span>{{ ppr.flightPlanNumber }}</span>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-overlay>
    </template>
  </b-sidebar>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import momentPlugin from '@fullcalendar/moment'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import UIHelper from '@/helpers/ui'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import AppDropZone from '@/components/AppDropZone.vue'
import { fetchTripStepPprRequest, patchTripStepSlotRequest } from '@/request/globalApi/requests/tripStepRequests'
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'
import { fetchTripStepAirportRequest } from '@/request/globalApi/requests/tripStepEventRequests'
import TimelineStepFlightPpr from '@/views/timeline/step/TimelineStepFlightPpr.vue'

export default {
  name: 'SidebarSubmitSlot',

  components: {
    FullCalendar,
    vueDropzone: AppDropZone,
    TimelineStepFlightPpr,
  },
  directives: { FullCalendar },
  mixins: [UIHelper],
  props: {
    stepEvent: {
      type: Object,
      default: () => ({}),
    },
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
    stepTripId: {
      type: Number,
      default: null,
    },
    stepType: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      slotsLoading: false,
      pprLoading: null,
      slotLoading: null,
      slotNumber: null,
      slotComment: null,
      ppr: {
        flightPlanNumber: null,
        passengers: null,
        crew: 0,
        flightDeparture: {
          pprRequestDate: null,
          startsAt: null,
          endsAt: null,
          airport: {
            id: null,
            name: null,
          },
          eventDateTime: null,
          address: {
            id: null,
            addressName: null,
            address: null,
            postalCode: null,
            city: null,
            countryCode: null,
          },
        },
        flightArrival: {
          pprRequestDate: null,
          startsAt: null,
          endsAt: null,
          airport: {
            id: null,
            name: null,
          },
          eventDateTime: null,
          address: {
            id: 0,
            addressName: null,
            address: null,
            postalCode: null,
            city: null,
            countryCode: null,
          },
        },
        trip: {
          organizationAircraft: {
            id: null,
            name: null,
          },
        },
      },
      pprActive: false,
      dateSlotSelected: '',
      timeSlotSelected: '',
      endTimeSlotSelected: '',
      currentDate: null,
      calendarOptions: {
        timeZone: 'UTC',
        plugins: [momentPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridWeek',
        allDaySlot: false,
        headerToolbar: {
          start: 'sidebarToggle, title',
          end: 'timeGridWeek,listMonth',
        },
        slotDuration: '01:00:00',
        slotLabelInterval: 60,
        events: [],
        selectable: true,
        eventResizableFromStart: true,
        dragScroll: true,
        navLinks: true,
        eventTimeFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        slotLabelFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        dayHeaderFormat: 'dd, D MMM',
        locale: this.$i18n.locale,
        viewDidMount: () => {
          const calendarApi = this.$refs.calendarSlot.getApi()
          calendarApi.gotoDate(this.$moment(this.dateSlotSelected).format('YYYY-MM-DD'))
        },
      },
      slotFiles: [],
      slotFileRemoved: false,
      savedFile: {},
      dropzoneFile: {
        url: 'https://localhost',
        dictDefaultMessage: this.$t('trip.validate_airport.submit_slot.upload_files'),
        thumbnailWidth: 300,
        thumbnailHeight: 150,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: 'image/*,application/pdf',
        addRemoveLinks: true,
        uploadMultiple: false,
      },
    }
  },
  watch: {
    value(val) {
      if (val) {
        window.dispatchEvent(new Event('resize'))
      }
    },
    dateSlotSelected: {
      handler() {
        this.fetchAirportSlots(this.stepEvent.airport.id, this.dateSlotSelected)
      },
    },
  },
  mounted() {
    const dateSlot = this.stepEvent.slotStartDate || this.stepEvent.eventDateTime
    const endDateSlot = this.stepEvent.slotEndDate || this.stepEvent.endsAt
    this.dateSlotSelected = this.$moment.utc(dateSlot).format('YYYY-MM-DD')
    this.timeSlotSelected = this.$moment.utc(dateSlot).format('HH:mm')
    this.endTimeSlotSelected = this.$moment.utc(endDateSlot).format('HH:mm')

    this.slotNumber = this.stepEvent.slotNumber
    this.slotComment = this.stepEvent.slotComment
  },
  methods: {
    /* CALL API */
    fetchAirportSlots(id, date) {
      this.calendarOptions.events = []
      if (this.$can('TRIP_SLOT_MANAGEMENT_VIEW')) {
        this.slotsLoading = true
        fetchTripStepAirportRequest(this.stepEvent.id, id, { estimatedDate: date })
          .then(response => {
            if (response.data.runwayAvailabilities.length > 0) {
              let slotId = 0
              response.data.runwayAvailabilities.forEach(availability => {
                availability.slots.forEach(slot => {
                  const startTime = this.$moment.utc(`${slot.startHours}:${slot.startMinutes}`, 'H:m').format('HH:mm')
                  const endTime = this.$moment.utc(`${slot.endHours}:${slot.endMinutes}`, 'H:m').format('HH:mm')
                  const start = this.$moment.utc(availability.date).format('YYYY-MM-DD')

                  const event = {
                    id: slotId++,
                    constraint: slot.quantity,
                    startTime,
                    endTime,
                    daysOfWeek: [availability.dayOfWeek === 7 ? 0 : availability.dayOfWeek],
                    color: slot.availabilityColor,
                    textColor: 'white',
                    start,
                    slot: slot.quantity,
                    extendedProps: { slots: { percentage: 100 } },
                  }
                  this.calendarOptions.events.push(event)
                })
              })
            }
          })
          .finally(() => {
            this.slotsLoading = false
          })
      }
    },
    postSlot(slot) {
      const eventId = this.stepEvent.id
      const tripId = Number(this.$route.params.trip_id)
      const stepTripId = this.$parent.$parent.$attrs.id.split('-')[1]
      if (this.$can('TRIP_SLOT_MANAGEMENT_EDIT')) {
        patchTripStepSlotRequest(stepTripId, eventId, slot).then(() => {
          this.$store.dispatch('trip/fetchTrip', { tripId, forced: true })
        })
      }
    },
    APIFetchTripStepPpr(stepId) {
      fetchTripStepPprRequest(stepId)
        .then(response => {
          this.ppr = response.data
          this.pprLoading = false
        })
        .catch(() => {
        }) // Why ??
    },
    pprView() {
      this.pprActive = !this.pprActive
      if (this.pprActive) {
        this.pprLoading = true
        this.APIFetchTripStepPpr(this.stepTripId)
      }
    },
    pprPrint() {
      window.print()
    },
    dropzoneMounted() {
      if (this.stepEvent.slotFileUrl !== null) {
        const file = { size: 200, name: this.stepEvent.slotFilePath, type: '' }
        this.$refs.submitSlotFile.manuallyAddFile(file, this.stepEvent.slotFileUrl)
      }
    },
    removeFile(fileRemoved) {
      this.slotFileRemoved = true
      this.slotFiles.forEach((file, index) => {
        if (file.uuid === undefined || file.uuid.includes(fileRemoved.upload.uuid)) {
          this.slotFiles.splice(index, 1)
        }
      })
    },
    sendFile(file) {
      const formData = new FormData()
      formData.append('temporary_file', file)

      postFileRequest(formData)
        .then(response => {
          this.savedFile = {
            id: null,
            fileId: response.data.id,
            deleteOldFile: false,
            uuid: file.upload.uuid,
          }
        })
        .finally(() => {
          this.slotFiles.push(this.savedFile)
        })
    },
    confirmSlot() {
      let legId
      let deleteOldFile
      this.stepType === 'departure' ? (legId = this.$parent.stepTrip.flightDeparture.id) : (legId = this.$parent.stepTrip.flightArrival.id)
      this.slotFileRemoved && this.stepEvent.slotFilePath !== null ? (deleteOldFile = true) : (deleteOldFile = false)
      const validateAirportUrl = window.location.origin
          + this.$router.resolve({
            name: 'airport-map',
            params: {
              trip_id: this.$router.currentRoute.params.trip_id,
              event_id: this.stepEvent.id,
              leg_id: legId,
            },
          }).href

      const slot = {
        slotNumber: this.slotNumber,
        slotStartDate: this.$moment.utc(`${this.dateSlotSelected} ${this.timeSlotSelected}`, 'YYYY-MM-DD HH:mm'),
        slotEndDate: this.$moment.utc(`${this.dateSlotSelected} ${this.endTimeSlotSelected}`, 'YYYY-MM-DD HH:mm'),
        slotComment: this.slotComment,
        slotFileId: this.savedFile.fileId,
        deleteOldSlotFilePath: deleteOldFile,
        validateAirportUrl,
      }
      this.$swal({
        title: this.$t('alert.delete_confirmation.title'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.$emit('input', false)
        if (result.value) {
          this.postSlot(slot)
        }
      })
    },
    onSubmit() {
      this.confirmSlot()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';

.ppr_infos {
  padding: 20px;

  .info {
    margin: 10px 0;
  }

  .specific {
    margin-top: 40px;
  }
}

@media print {
  #sidebar-submitSlot {
    width: 100vw;
    font-size: 20px;
  }
  .ppr-no-print {
    display: none;
  }
  h5 {
    font-size: 40px;
    text-align: center;
  }
}
</style>
