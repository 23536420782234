<template>
  <div :id="`${id}-aircraft-luggage-luggage-btn`">
    <b-button v-ripple.400 size="sm" variant="light" v-bind="$attrs" v-on="$listeners">
      <font-awesome-icon class="mr-50" icon="suitcase" />
      <span v-if="luggage.bags"> {{ luggage.bags }} {{ $tc('luggage.bags', luggage.bags) }} </span>
      <span v-if="luggage.bags" class="mx-25"> / </span>
      <span v-if="luggage.luggage"> {{ luggage.luggage }} {{ $tc('luggage.luggage', luggage.luggage) }} </span>
      <span v-if="!luggage.bags && !luggage.luggage"> {{ $tc('luggage.no_informations') }} </span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'AircraftPeopleLuggageBtn',

  props: {
    id: {
      type: String,
      default: 'aircraft-people-luggage-btn',
    },
    luggage: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
