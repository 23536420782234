<template>
  <b-sidebar id="sidebar-assignHelicopter" :visible="isAssignHelicopterSidebarActive" lasy no-header @change="val => $emit('update:is-assign-helicopter-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex align-items-center content-sidebar-header w-100">
        <h5 class="mb-0">
          {{ $t('timeline.helicopter.assign') }}
        </h5>
        <feather-icon class="mr-1 ml-auto cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <b-card-body>
        <b-overlay :show="depositZonesLoading" no-fade>
          <validation-observer v-if="!depositZonesLoading" ref="assignHelicopterSubmitRules" #default="{ handleSubmit }">
            <!-- Form -->
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <!-- Helicopter -->
              <b-form-group :label="$t('trip.helicopter.assign_immatriculation')" label-for="organization-helicopter">
                <v-select
                  v-model="organizationHelicopterSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :get-option-label="organizationHelicopter => organizationHelicopter.modelType + ' / ' + organizationHelicopter.registration"
                  input-id="organization-helicopter"
                  :options="organizationHelicopters"
                />
              </b-form-group>

              <!-- Flying time -->
              <b-form-group :label="$t('trip.helicopter.flying_time') + ' *'" label-for="helicopter-flying-time">
                <app-input
                  id="helicopter-flying-time"
                  v-model.number="flyingTime"
                  :append="$t('common.min')"
                  type="number"
                  step="1"
                  rules="required|positive|integer"
                />
              </b-form-group>

              <!-- Departure deposit zone -->
              <b-form-group class="select-options" :label="$t('trip.shop.departure_deposit_zone')" label-for="departure-deposit-zone">
                <v-select
                  v-if="departureDepositZones.length"
                  v-model="departureDepositZoneId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="departureDepositZones"
                  :reduce="val => val.id"
                  :clearable="false"
                  label="address"
                  input-id="departure-deposit-zone"
                />
                <div v-else class="d-flex justify-content-between align-items-center">
                  <label class="search-autocomplete_map">
                    <b-form-group>
                      <GmapAutocomplete id="departure-address-on-quote" ref="gmapDeparture" class="form-control" @place_changed="setDepartureOnQuoteAddressPlace" />
                    </b-form-group>
                  </label>
                  <b-link v-if="departureDepositZoneAddress" target="_blank" :href="departureDepositZoneAddress.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                </div>

                <!-- OTHER DEPARTURE ADDRESS FOR REGULAR FLIGHT-->
                <div v-if="departureDepositZoneId === 0" class="d-flex justify-content-between align-items-center">
                  <label class="search-autocomplete_map">
                    <b-form-group label-for="departure-address">
                      <GmapAutocomplete id="departure-address" ref="gmapDeparture" class="form-control" :value="departureDepositZoneAddress ? getDetailedAddress(departureDepositZoneAddress) : null" @place_changed="setDepartureAddressPlace" />
                    </b-form-group>
                  </label>
                  <b-link v-if="departureDepositZoneAddress" target="_blank" :href="departureDepositZoneAddress.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                </div>
              </b-form-group>

              <!-- Arrival deposit zone -->
              <b-form-group class="select-options" :label="$t('trip.shop.arrival_deposit_zone')" label-for="arrival-deposit-zone">
                <v-select
                  v-if="arrivalDepositZones.length"
                  v-model="arrivalDepositZoneId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="arrivalDepositZones"
                  :reduce="val => val.id"
                  label="address"
                  :clearable="false"
                  input-id="arrival-deposit-zone"
                />
                <div v-else class="d-flex justify-content-between align-items-center">
                  <label class="search-autocomplete_map">
                    <b-form-group>
                      <GmapAutocomplete id="arrival-address-on-quote" ref="gmapDeparture" class="form-control" @place_changed="setArrivalOnQuoteAddressPlace" />
                    </b-form-group>
                  </label>
                  <b-link v-if="arrivalDepositZoneAddress" target="_blank" :href="arrivalDepositZoneAddress.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                </div>
                <!-- OTHER ARRIVAL ADDRESS FOR REGULAR FLIGHT-->
                <div v-if="arrivalDepositZoneId === 0" class="d-flex justify-content-between align-items-center">
                  <label class="search-autocomplete_map">
                    <b-form-group label-for="arrival-address">
                      <GmapAutocomplete id="arrival-address" ref="gmapDeparture" :value="arrivalDepositZoneAddress ? getDetailedAddress(arrivalDepositZoneAddress) : null" class="form-control" @place_changed="setArrivalAddressPlace" />
                    </b-form-group>
                  </label>
                  <b-link v-if="arrivalDepositZoneAddress" target="_blank" :href="arrivalDepositZoneAddress.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                </div>
              </b-form-group>

              <div class="d-flex mt-2 justify-content-end flex-column">
                <b-button block class="mr-2" type="submit">
                  <font-awesome-icon icon="save" />
                  {{ $t('action.save') }}
                </b-button>

                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class="mt-2" variant="outline-secondary" @click="hide">
                  <font-awesome-icon class="mr-50" icon="ban" />
                  {{ $t('action.cancel') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-card-body>
    </template>
  </b-sidebar>
</template>
<script>

import {
  fetchTripStepTransferDepositZonesRequest,
  patchTripStepTransferDepositZonesRequest,
} from '@/request/globalApi/requests/tripStepEventRequests'
import { intersection } from 'lodash'

export default {
  name: 'SidebarAssignHelicopter',
  model: {
    prop: 'isAssignHelicopterSidebarActive',
    event: 'update:is-assign-helicopter-sidebar-active',
  },
  props: {
    isAssignHelicopterSidebarActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      googleMapExternalUrl: 'https://maps.google.com/?q=',
      depositZonesLoading: true,
      organizationHelicopters: [],
      departureDepositZones: [
        { id: 0, address: this.$t('aircraft.select_other') },
      ],
      eventDepartureUtcOffset: 0,
      departureDepositZoneId: 0,
      departureDepositZoneAddress: null,
      arrivalDepositZones: [
        { id: 0, address: this.$t('aircraft.select_other') },
      ],
      eventArrivalUtcOffset: 0,
      arrivalDepositZoneId: 0,
      arrivalDepositZoneAddress: null,
      organizationHelicopterSelected: null,
      flyingTime: 0,
    }
  },
  mounted() {
    this.flyingTime = this.stepTrip.flyingTime
    this.organizationHelicopterSelected = this.stepTrip.transferDeparture.organizationHelicopter
    this.departureDepositZoneId = this.stepTrip.departureDepositZone ? this.stepTrip.departureDepositZone.id : null
    this.arrivalDepositZoneId = this.stepTrip.arrivalDepositZone ? this.stepTrip.arrivalDepositZone.id : null
    this.departureDepositZoneAddress = this.stepTrip.departureDepositZone
    this.arrivalDepositZoneAddress = this.stepTrip.arrivalDepositZone

    if (this.departureDepositZoneAddress) {
      this.departureDepositZoneAddress.url = `${this.googleMapExternalUrl}${this.departureDepositZoneAddress.latitude},${this.departureDepositZoneAddress.longitude}`
    }

    if (this.arrivalDepositZoneAddress) {
      this.arrivalDepositZoneAddress.url = `${this.googleMapExternalUrl}${this.departureDepositZoneAddress.latitude},${this.departureDepositZoneAddress.longitude}`
    }

    this.APIFetchTripStepTransferDepositZones()
  },
  methods: {
    setDepartureAddressPlace(place) { this.setAPIPlace(place, 'departureDepositZoneAddress', '') },
    setDepartureOnQuoteAddressPlace(place) { this.setAPIPlace(place, 'departureDepositZoneAddress', '') },
    setArrivalAddressPlace(place) { this.setAPIPlace(place, 'arrivalDepositZoneAddress', '') },
    setArrivalOnQuoteAddressPlace(place) { this.setAPIPlace(place, 'arrivalDepositZoneAddress', '') },
    APIFetchTripStepTransferDepositZones() {
      this.depositZonesLoading = true
      fetchTripStepTransferDepositZonesRequest(this.stepTrip.id)
        .then(response => {
          this.organizationHelicopters = response.data.organizationHelicopters

          this.departureDepositZones = response.data.departureDepositZones
          this.departureDepositZones.unshift({ id: 0, address: this.$t('aircraft.select_other') })
          const selectedDeparture = this.departureDepositZones.filter(depositZone => this.departureDepositZoneId === depositZone.id)
          if (selectedDeparture.length === 0) {
            this.departureDepositZoneId = 0
          }

          this.arrivalDepositZones = response.data.arrivalDepositZones
          this.arrivalDepositZones.unshift({ id: 0, address: this.$t('aircraft.select_other') })
          const selectedArrival = this.arrivalDepositZones.filter(depositZone => this.arrivalDepositZoneId === depositZone.id)
          if (selectedArrival.length === 0) {
            this.arrivalDepositZoneId = 0
          }
        })
        .finally(() => {
          this.depositZonesLoading = false
        })
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.assignHelicopterSubmitRules) {
          this.$refs.assignHelicopterSubmitRules.reset()
        }
      })
    },
    validationForm() {
      this.$refs.assignHelicopterSubmitRules.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
    onSubmit() {
      this.depositZonesLoading = true
      const payload = {
        flyingTime: this.flyingTime,
        organizationHelicopterId: this.organizationHelicopterSelected.id,
        departureDepositZoneId: this.departureDepositZoneId !== 0 ? this.departureDepositZoneId : null,
        departureAddress: this.departureDepositZoneAddress,
        arrivalDepositZoneId: this.arrivalDepositZoneId !== 0 ? this.arrivalDepositZoneId : null,
        arrivalAddress: this.arrivalDepositZoneAddress,
      }

      patchTripStepTransferDepositZonesRequest(this.stepTrip.id, payload).then(() => {
        this.stepTrip.flyingTime = this.flyingTime
        this.stepTrip.transferDeparture.organizationHelicopter = this.organizationHelicopterSelected
        this.$emit('update:is-assign-helicopter-sidebar-active', false)
      }).finally(() => {
        this.depositZonesLoading = false
      })
    },
    setAPIPlace(place, target, subTarget) {
      let streetNumber = null
      let route = null
      let postalCode = null
      let countryName = null
      let countryCode = null
      let locality = null
      let address = null
      const latitude = place.geometry.location.lat()
      const longitude = place.geometry.location.lng()
      const timeZone = place.utc_offset_minutes
      if (target === 'departureDepositZoneAddress') {
        this.eventDepartureUtcOffset = timeZone
      } else if (target === 'arrivalDepositZoneAddress') {
        this.eventArrivalUtcOffset = timeZone
      }

      place.address_components.forEach(addr => {
        if (addr.types.includes('street_number')) streetNumber = addr.short_name
        else if (addr.types.includes('route')) route = addr.short_name
        else if (addr.types.includes('postal_code')) postalCode = addr.short_name
        else if (addr.types.includes('country')) {
          countryName = addr.long_name
          countryCode = addr.short_name
        } else if (intersection(addr.types, ['locality', 'postal_town', 'sublocality_level_1', 'route']).length) locality = addr.short_name
      })

      if (route) {
        streetNumber ? (address = `${streetNumber} ${route}`) : (address = route)
      } else {
        address = place.name
      }
      const addressObject = {
        addressName: place.name,
        address,
        postalCode: postalCode ?? '00000',
        city: locality,
        countryCode,
        countryName,
        latitude,
        longitude,
        url: `${this.googleMapExternalUrl}${latitude},${longitude}`,
      }

      if (target === 'departureDepositZoneAddress') {
        subTarget !== '' ? this.departureDepositZoneAddress[subTarget] = addressObject : this.departureDepositZoneAddress = addressObject
      } else if (target === 'arrivalDepositZoneAddress') {
        subTarget !== '' ? this.arrivalDepositZoneAddress[subTarget] = addressObject : this.arrivalDepositZoneAddress = addressObject
      }
    },
    getDetailedAddress(address) {
      return `${address.address}, ${address.postalCode} ${address.city}, ${address.countryName}`
    },
  },
}
</script>
