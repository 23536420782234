<template>
  <section>
    <b-row>
      <b-col md="5" class="d-flex align-items-end">
        <slot name="legDetailName">
          <span class="h3">{{ $t('trip.flight_leg_details') }}</span>
        </slot>
      </b-col>

      <b-col md="7">
        <trip-leg-selector :trip-data="tripData" />
      </b-col>
    </b-row>

    <trip-leg-collapsible
      :trip-data="tripData"
      :trip-steps="tripData.tripSteps"
      :trip-step-events="tripStepEvents"
      @refetch-data="$emit('refetch-data')"
    />
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import TripLegSelector from '@/views/trip/leg/TripLegSelector.vue'
import TripLegCollapsible from '@/views/trip/leg/TripLegCollapsible.vue'

export default {
  name: 'TripLegDetails',

  components: {
    TripLegCollapsible,
    TripLegSelector,
  },
  mixins: [UIHelper],
  props: {
    tripData: {
      type: Object,
      default: () => ({}),
    },
    tripStepEvents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDetails: true,
    }
  },
  computed: {
    legsEvent() {
      const legs = []
      let departure = null
      this.tripData.tripSteps.forEach(leg => {
        if (leg.categorie === 'departure') {
          departure = leg
        }
        if (leg.categorie === 'arrival') {
          const arrivalDeparture = [departure, leg]
          legs.push({ ...arrivalDeparture })
          departure = null
        }
      })
      return legs
    },
    legOptions() {
      const options = [
        {
          label: this.$t('trip.event.hide_all'),
          value: 'hide',
          data: this.tripData.tripSteps,
          index: `${this.tripData.id}-hide`,
        },
        {
          label: this.$t('trip.all_legs'),
          value: 'all',
          data: this.tripData.tripSteps,
          index: `${this.tripData.id}-all`,
        },
      ]
      let departure = null
      let index = 1
      this.tripData.tripSteps.forEach(leg => {
        if (leg.categorie === 'departure') {
          departure = leg
        }
        if (leg.categorie === 'arrival') {
          const legName = `${departure.city.label}/${leg.city.label}`
          options.push({
            label: legName,
            value: departure.id,
            data: [departure, leg],
            index: `${this.tripData.id}-${index}`,
          })
          index += 1
          departure = null
        }
      })
      return options
    },
  },
}
</script>
