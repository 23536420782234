<template>
  <section class="border rounded mb-1">
    <b-overlay :show="gendecLoading">
      <b-alert class="m-0" show variant="primary">
        <div class="alert-body d-flex justify-content-end">
          <b-button v-if="$can('TRIP_EDIT')" v-ripple.400 class="mr-1" size="sm" @click="isAddNewPassengerSidebarActive = true">
            <font-awesome-icon class="mr-50" icon="plus" />
            {{ $t('crew.action.assign_crew') }}
          </b-button>
          <b-button v-if="$can('TRIP_GENDEC_MANAGEMENT_ADD')" v-ripple.400 class="mr-1" size="sm" @click="isAddGendecSidebarActive = true">
            <font-awesome-icon class="mr-50" icon="plus" />
            {{ $t('trip.gendec.add_gendec') }}
          </b-button>
        </div>
      </b-alert>
      <b-table v-if="gendecs.length" :fields="fieldsGendec" :items="gendecs" class="position-relative m-0" responsive striped>
        <template #cell(providers)="data">
          <div v-for="(provider, index) in data.item.providers" :key="index">
            {{ provider.legalName }} <span v-if="index !== (data.item.providers.length - 1)"> - </span>
          </div>
        </template>
        <template #cell(date)="data">
          <div>
            {{ $moment.utc(data.item.date).format('ddd, MMM Do YYYY') }}
          </div>
        </template>
        <template #cell(gendecFiles)="data">
          <div v-for="(file, index) in data.item.gendecFiles" :key="index">
            <b-link v-if="file.fileUrl" :href="file.fileUrl" target="_blank">
              {{ file.filePath }}
            </b-link>
            <span v-else>{{ file.filePath }}</span>
            <span v-if="index !== (data.item.gendecFiles.length - 1)"> - </span>
          </div>
        </template>
        <template #cell(message)="data">
          <div v-for="(message, index) in data.item.message" :key="index">
            <span v-if="index === userLang"> {{ message }} </span>
          </div>
        </template>
      </b-table>
    </b-overlay>
    <sidebar-add-passenger
      v-if="$can('TRIP_EDIT')"
      :is-add-new-passenger-sidebar-active.sync="isAddNewPassengerSidebarActive"
      :step-trip="stepTrip"
      @refetch-data="refetchData"
    />
    <!-- TODO: prop required doesn't provide -->
    <sidebar-add-gendec
      v-if="$can('TRIP_GENDEC_MANAGEMENT_ADD') && stepTrip.className === 'TripFlight'"
      :is-add-gendec-sidebar-active.sync="isAddGendecSidebarActive"
      :step-event="stepEvent"
      :step-trip="stepTrip"
      @refetch-data="refetchData"
    />
  </section>
</template>

<script>
import SidebarAddPassenger from '@/views/timeline/sidebar/SidebarAddCrew.vue'
import SidebarAddGendec from '@/views/timeline/sidebar/SidebarAddGendec.vue'
import AccessControl from '@/helpers/accessControl'
import UIHelper from '@/helpers/ui'
import { deleteTripStepParticipantRequest } from '@/request/globalApi/requests/tripStepParticipantRequests'
import {
  fetchTripStepGendecRequest,
  patchTripStepDisabledParticipantRequest,
} from '@/request/globalApi/requests/tripStepRequests'

export default {
  name: 'TripPassengerList',

  components: {
    SidebarAddPassenger,
    SidebarAddGendec,
  },
  mixins: [AccessControl, UIHelper],
  props: {
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
    stepEvent: {
      type: Object,
      default: () => ({}),
    },
    tripOrganizationId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gendecLoading: false,
      gendecs: [],
      isAddNewPassengerSidebarActive: false,
      isAddGendecSidebarActive: false,
      paxDisabled: this.$t('common.no'),
      fieldsGendec: [
        { key: 'date', label: this.$t('common.date') },
        { key: 'providers', label: this.$t('trip.gendec.send_to') },
        { key: 'gendecFiles', label: this.$t('trip.gendec.documents') },
        { key: 'message', label: this.$t('common.message') },
      ],
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  mounted() {
    this.initDataOnCollapse()
  },
  methods: {
    initDataOnCollapse() {
      this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
        if (collapseId === `collapse-crew-${this.stepEvent.id}` && isJustShown) {
          this.APIFetchTripStepGendec()
        }
      })
    },
    APIFetchTripStepGendec() {
      if (this.$can('TRIP_GENDEC_MANAGEMENT_VIEW')) {
        this.gendecLoading = true
        fetchTripStepGendecRequest(this.stepTrip.id, { getAllItems: true })
          .then(r => {
            this.gendecs = r.data.gendecs
          })
          .finally(() => {
            this.gendecLoading = false
          })
      }
    },
    APIPatchTripStepDisabledParticipant(e) {
      patchTripStepDisabledParticipantRequest(this.stepTrip.id, { disabledParticipant: e })
        .then(() => {})
        .catch(() => {
          this.stepTrip.disabledParticipant = !this.stepTrip.disabledParticipant
        })
    },
    APIDeleteTripStepParticipant(crewId) {
      deleteTripStepParticipantRequest(this.stepTrip.id, crewId).then(() => {
        this.refetchData()
      })
    },
    refetchData() {
      this.APIFetchTripStepGendec()
    },
  },
}
</script>
