<template>
  <b-sidebar
    id="sidebar-airportInformations"
    no-header
    lasy
    :visible="value"
    @change="val => $emit('input', val)"
  >
    <template #default="{ hide }">
      <!-- HEADER -->
      <div class="airport-infos-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('timeline.validate_airport.airport_infos.informations') }}
        </h5>
        <div>
          <feather-icon class="ml-1 cursor-pointer airport-no-print" icon="PrinterIcon" size="16" @click.prevent="airportInfosPrint()" />
          <feather-icon class="ml-1 cursor-pointer airport-no-print" icon="XIcon" size="16" @click="hide" />
        </div>
      </div>
      <b-overlay :show="airportLoading">
        <!-- BODY -->
        <b-card-body class="mt-2">
          <b-card-text class="mb-2">
            <span class="font-weight-bold">{{ $t('common.country') }} : </span>
            <span>{{ airport.country }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.distance') }} : </span>
            <app-not-specified-default :text="airport.distanceFromCity" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.airport_name') }} : {{ airport.name }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.airport_name_short') }} : </span>
            <span>{{ airport.airportNameShort }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.icao') }} : {{ airport.icaoCode }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.iata') }} : </span>
            <app-not-specified-default :text="airport.iataCode" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('common.country') }} : {{ airport.country }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('address.city') }} : {{ airport.city }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('common.latitude') }} : {{ airport.latitude }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('common.longitude') }} : {{ airport.longitude }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.handling_mandatory') }} : </span>
            <app-not-specified-default :text="airport.handlingMandatory" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.runway_width') }} : </span>
            <app-not-specified-default
              :text="airport.runwayWidth ? airport.runwayWidth + $getMeasurementUnitItn('m') : null"
            />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.runway_length') }} : </span>
            <app-not-specified-default
              :text="airport.runwayLength ? airport.runwayLength + $getMeasurementUnitItn('m') : null"
            />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.control_tower_hours') }} : </span>
            <app-not-specified-default :text="airport.controlTowerHours" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.airport_of_entry') }} : </span>
            <app-not-specified-default :text="airport.airportOfEntry" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.daylight_savings') }} : </span>
            <app-not-specified-default :text="airport.daylightSavings" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.timezone_lag') }} : </span>
            <app-not-specified-default :text="airport.timezoneLag" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.variation') }} : </span>
            <app-not-specified-default :text="airport.variation" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.elevation') }} : </span>
            <app-not-specified-default :text="airport.elevation" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.airport_type') }} : </span>
            <app-not-specified-default :text="airport.airportType" />
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.slot_required') }} : </span>
            <span>{{ airport.congested ? $t('common.yes') : $t('common.no') }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.fuel_type') }} : </span>
            <span>{{ airport.fuelType }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.customs') }} : </span>
            <span>{{ airport.customs ? $t('common.yes') : $t('common.no') }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.open24_hours') }} : </span>
            <span>{{ airport.open24Hours ? $t('common.yes') : $t('common.no') }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.approach_list') }} : </span>
            <span>{{ airport.approachList }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.primary_runway_id') }} : </span>
            <span>{{ airport.primaryRunwayId }}</span>
          </b-card-text>
          <b-card-text class="mb-1">
            <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.lighting') }} : </span>
            <span>{{ airport.lighting }}</span>
          </b-card-text>
          <div v-if="airport.address">
            <b-card-title class="mb-50 mt-50"> {{ $t('common.address') }} :</b-card-title>
            <b-card-text
              v-if="airport.address.addressName"
              class="mb-1"
            ><span> {{ airport.address.addressName }} </span></b-card-text>
            <b-card-text
              class="mb-1"
            ><span>{{ airport.address.address }}</span></b-card-text>
            <b-card-text
              v-if="airport.address.complementaryAddress"
              class="mb-1"
            ><span>{{ airport.address.complementaryAddress }}</span></b-card-text>
            <b-card-text
              class="mb-1"
            ><span>{{ airport.address.postalCode }} - {{ airport.address.city }}</span></b-card-text>
            <b-card-text
              class="mb-1"
            ><span>{{ airport.address.countryName }}</span></b-card-text>
            <b-card-text
              v-if="airport.address.contactName"
              pr
              class="mb-1"
            ><span>{{ airport.address.contactName }}</span></b-card-text>
            <b-card-text
              v-if="airport.address.contactEmail"
              class="mb-1"
            ><span>{{ airport.address.contactEmail }}</span></b-card-text>
            <b-card-text
              v-if="airport.address.contactPhone"
              class="mb-1"
            ><span>{{ airport.address.contactPhone }}</span></b-card-text>
            <b-card-text
              v-if="airport.address.additionalInformations"
              class="mb-1"
            ><span>{{ airport.address.additionalInformations }}</span></b-card-text>
            <b-card-text
              v-if="airport.address.buildingCode"
              class="mb-1"
            ><span>{{ airport.address.buildingCode }}</span></b-card-text>
          </div>
        </b-card-body>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import { fetchAirportRequest } from '@/request/globalApi/requests/airportRequests'
import AppNotSpecifiedDefault from '@/components/AppNotSpecifiedDefault.vue'

export default {
  name: 'SidebarAirportInformations',

  components: {
    AppNotSpecifiedDefault,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    airportId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      airport: {},
      airportLoading: false,
    }
  },
  mounted() {
    this.airportLoading = true
    fetchAirportRequest(this.airportId)
      .then(response => {
        this.airport = response.data
      })
      .finally(() => {
        this.airportLoading = false
      })
  },
  methods: {
    airportInfosPrint() {
      window.print()
    },
  },
}
</script>
<style lang="scss">
@media print {
  #app {
    height: initial !important;
  }
  #sidebar-airportInformations {
    width: 100vw;
    font-size: 20px;
    .airport-infos-header {
      justify-content: center;
    }
    .airport-no-print {
      display: none;
    }
    h5 {
      font-size: 40px;
      margin-bottom: 50px;
    }
    .card-text span {
      font-size: 20px;
    }
    .card-body {
      margin-top: 3rem;
    }
  }
}
</style>
