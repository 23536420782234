<template>
  <section class="rounded">
    <!-- HEADER -->
    <div class="d-flex justify-content-between align-items-center flex-sm-row flex-column flex-wrap mb-1 mb-sm-50 mr-1">
      <div class="d-flex align-items-center">
        <span class="font-small-4 font-weight-bold">
          <span v-if="stepType === 'departure'">{{ $t('trip.departure_from') }}</span>
          <span v-else>{{ $t('trip.arrival_to') }}</span>
        </span>
        <span class="mx-50"> : </span>
        <span v-if="stepEvent.airport" class="mr-1">
          <b-button
            v-if="stepEvent.airport"
            v-ripple.400
            class="d-flex align-items-center"
            size="sm"
            variant="outline-secondary"
            @click="isAirportInformationsSidebarActive = !isAirportInformationsSidebarActive"
          >
            {{ stepEvent.airport.name }} - {{ stepEvent.airport.icaoCode }}
          </b-button>
        </span>
        <span v-else class="mr-1">{{ stepEvent.address.address }}</span>

        <section class="d-flex align-items-center">
          <div>
            <span class="font-small-3 mr-50">{{ stepType === 'departure' ? $t('trip.event.etd') : $t('trip.event.eta') }} :</span>
            <span class="mr-50">
              <font-awesome-icon icon="calendar-alt" />
              <!-- TODO: refacto it -->
              {{
                stepEvent.slotDate ? $moment.utc(stepEvent.slotDate).format('ddd, MMM Do YYYY') : $moment.utc(stepEvent.eventDateTime).format('ddd, MMM Do YYYY')
              }}
            </span>
            <span class="mr-50">
              <font-awesome-icon icon="clock" />
              {{ stepEvent.slotDate ? $moment.utc(stepEvent.slotDate).format('HH:mm') : $moment.utc(stepEvent.eventDateTime).format('HH:mm') }}
              <span v-if="stepEvent.airport && stepEvent.airport.congested"> - {{ $moment.utc(stepEvent.endsAt).utc().format('HH:mm') }} </span>
              {{ $t('common.utc') }}
            </span>
          </div>
        </section>
        <span>
          <TimelineStepFlightSlotStatus
            v-if="stepEvent.startsAt && stepEvent.endsAt && (isFixedBaseOperator || isBuyer)"
            :is-buyer="isBuyer"
            :step-event="stepEvent"
            :step-trip="stepTrip"
            :step-type="stepType"
            @refetch-data="$emit('refetch-data')"
          />
        </span>
      </div>

      <div>
        <!-- EDIT LEG -->
        <b-button
          v-if="stepType === 'departure' && ($can('TRIP_EDIT') || $can('TRIP_DELETE'))"
          v-ripple.400
          class="mr-1"
          size="sm"
          @click="isEditLegSidebarActive = !isEditLegSidebarActive"
        >
          <font-awesome-icon class="mr-50" icon="edit" />
          {{ $t('trip.action.edit_leg') }}
        </b-button>
      </div>
    </div>

    <!-- BODY -->
    <span>
      <section>
        <div>
          <!-- Departure confirmed -->
          <!-- ACTION CREW LIST -->
          <AircraftPeopleBtn
            v-if="stepType !== 'arrival'"
            :id="`timeline-step-flight-${stepTrip.id}`"
            v-b-toggle="'collapse-crew-' + stepEvent.id"
            :people="stepTrip"
            :pressed="eventDetails.includes('crew' + stepEvent.id) ? true : false"
            class="my-50"
            show-chevron
            @click="toggleEventDetails('crew' + stepEvent.id)"
          />
          <!-- ACTION CREW LIST -->
        </div>
        <!--LIST CREWS-->
        <b-collapse :id="'collapse-crew-' + stepEvent.id" type="border">
          <TimelineStepParticipant :step-event="stepEvent" :step-trip="stepTrip" />
        </b-collapse>
      </section>

      <b-card-text>
        <!-- AIRPORT UNSELECTED -->
        <section v-if="!stepEvent.airport">
          <span v-if="$can('TRIP_EDIT')">
            <b-button
              :to="{
                name: 'airport-map',
                params: {
                  trip_id: $route.params.trip_id,
                  event_id: stepTrip.id,
                  leg_id: stepEvent.id,
                },
              }"
              class="mr-1"
              size="sm"
            >
              <font-awesome-icon class="mr-50" icon="plane" />
              {{ $t('timeline.validate_airport.title') }}
            </b-button>
          </span>
        </section>

        <!-- AIRPORT APPROVED -->
        <section v-else>
          <div v-if="$can('TRIP_SLOT_MANAGEMENT_VIEW')" class="d-flex mb-1 justify-content-start align-items-center">
            <span v-if="isFixedBaseOperator">
              <b-button v-show="!stepTrip.slotNumber" v-ripple.400 class="mr-1" size="sm" @click="isAddNewSlotSidebarActive = !isAddNewSlotSidebarActive">
                <font-awesome-icon class="mr-50" icon="plane" />
                <span v-if="stepEvent.slotNumber">
                  {{ $t('trip.event.slot.edit') }}
                </span>
                <span v-else-if="stepEvent.startsAt && stepEvent.endsAt">
                  {{ $t('trip.event.slot.submit') }}
                </span>
              </b-button>
            </span>
            <!-- PPR -->
            <TimelineStepFlightPpr v-if="stepEvent.allowedToSeePpr" :step-event="stepEvent" :step-trip="stepTrip" :step-type="stepType" />
          </div>

          <!-- ADD SERVICE -->
          <span v-if="$can('TRIP_EDIT') && stepEvent.airport && stepEvent.startsAt" id="tooltipTarget">
            <b-button
              v-if="stepEvent.tripStepProviders.some(provider => provider.type !== 'service') || stepEvent.tripStepProviders.length"
              v-ripple.400
              :disabled="!stepTrip.flightDeparture.airport || !stepTrip.flightArrival.airport"
              :to="{
                name: 'provider-list',
                params: {
                  trip_id: $route.params.trip_id,
                  step_id: stepTrip.id,
                  event_id: stepEvent.id,
                  airport_id: stepEvent.airport.id,
                },
              }"
              class="mr-1"
              size="sm"
            >
              <font-awesome-icon class="mr-50" icon="edit" />
              {{ $t('timeline.ground_handling.edit') }}
            </b-button>

            <b-button
              v-else
              v-ripple.400
              :disabled="!stepTrip.flightDeparture.airport || !stepTrip.flightArrival.airport"
              :to="{
                name: 'provider-list',
                params: {
                  trip_id: $route.params.trip_id,
                  step_id: stepTrip.id,
                  event_id: stepEvent.id,
                  airport_id: stepEvent.airport.id,
                },
              }"
              class="mr-1"
              size="sm"
            >
              <font-awesome-icon class="mr-50" icon="plus" />
              {{ $t('timeline.ground_handling.add') }}
            </b-button>

            <!-- ADD FUEL -->
            <b-button
              v-ripple.400
              :disabled="!stepTrip.flightDeparture.airport || !stepTrip.flightArrival.airport"
              :to="{
                name: 'fuel',
                params: {
                  trip_id: $route.params.trip_id,
                  event_id: stepEvent.id,
                },
              }"
              class="mr-1"
              size="sm"
            >
              <font-awesome-icon class="mr-50" icon="plus" />
              {{ $t('timeline.fuel.add') }}
            </b-button>

            <!-- ADD HANGAR -->
            <b-button
              v-ripple.400
              :disabled="!stepTrip.flightDeparture.airport || !stepTrip.flightArrival.airport"
              :to="{
                name: 'hangar',
                params: {
                  trip_id: $router.currentRoute.params.trip_id,
                  event_id: stepEvent.id,
                },
              }"
              class="mr-1"
              size="sm"
            >
              <font-awesome-icon class="mr-50" icon="plus" />
              {{ $t('timeline.hangar.add') }}
            </b-button>
          </span>
          <b-tooltip
            v-if="!stepTrip.flightDeparture.airport || !stepTrip.flightArrival.airport"
            boundary-padding="0"
            placement="topRight"
            target="tooltipTarget"
            triggers="hover"
            variant="light"
          >
            <b-card border-variant="primary" class="m-0">
              {{ $t('timeline.validate_airport.check') }}
            </b-card>
          </b-tooltip>
        </section>
      </b-card-text>
    </span>
    <div />

    <!--LIST PROVIDER-->
    <div v-if="displayProvider" class="mt-1">
      <timeline-step-provider
        :step-event="stepEvent"
        :step-trip="stepType === 'departure' ? stepTrip.flightDeparture : stepTrip.flightArrival"
        :trip-data="tripData"
      />
    </div>

    <sidebar-submit-slot
      v-if="$can('TRIP_SLOT_MANAGEMENT_VIEW') && isFixedBaseOperator"
      v-model="isAddNewSlotSidebarActive"
      :step-event="stepEvent"
      :step-trip="stepTrip"
      :step-trip-id="stepTrip.id"
      :step-type="stepType"
    />

    <sidebar-edit-leg :is-edit-leg-sidebar-active.sync="isEditLegSidebarActive" :step-event="stepEvent" :step-trip="stepTrip" />

    <!-- SIDEBAR AIRPORT INFORMATIONS -->
    <SidebarAirportInformations
      v-if="stepEvent.airport"
      v-model="isAirportInformationsSidebarActive"
      :airport-id="stepEvent.airport.id"
    />
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import AccessControl from '@/helpers/accessControl'
import timelineStepProvider from '@/views/timeline/step/provider/TimelineStepProvider.vue'
import TimelineStepParticipant from '@/views/timeline/step/participant/TimelineStepParticipant.vue'
import SidebarSubmitSlot from '@/views/timeline/sidebar/SidebarSubmitSlot.vue'
import SidebarEditLeg from '@/views/timeline/sidebar/SidebarEditLeg.vue'
import SidebarAirportInformations from '@/views/timeline/sidebar/SidebarAirportInformations.vue'
import TimelineStepFlightPpr from '@/views/timeline/step/TimelineStepFlightPpr.vue'
import AircraftPeopleBtn from '@/components/AircraftPeopleBtn.vue'

import { mapState } from 'vuex'

export default {
  name: 'TimelineStepFlight',

  components: {
    SidebarSubmitSlot,
    SidebarAirportInformations,
    timelineStepProvider,
    TimelineStepParticipant,
    SidebarEditLeg,
    TimelineStepFlightPpr,
    AircraftPeopleBtn,
    TimelineStepFlightSlotStatus: () => import('./TimelineStepFlightSlotStatus.vue'),
  },
  mixins: [UIHelper, AccessControl],
  props: {
    stepType: { type: String, default: '' },
    stepId: { type: String, default: '' },
    tripData: {
      type: Object,
      default: () => ({}),
    },
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
    stepEvent: {
      type: Object,
      default: () => ({}),
    },
    displayProvider: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      eventDetails: [],
      provider: null,
      isFixedBaseOperator: false,
      isBuyer: false,
      isAddNewSlotSidebarActive: false,
      isEditLegSidebarActive: false,
      isAirportInformationsSidebarActive: false,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  mounted() {
    this.isFixedBaseOperator = this.stepEvent?.fixedBaseOperator?.id === this.selectedOrganization.id
    this.isBuyer = this.selectedOrganization.id === this.tripData.organization.id
  },
  methods: {
    toggleEventDetails(eventId) {
      const detailFound = this.eventDetails.includes(eventId)
      if (detailFound) {
        this.eventDetails = this.eventDetails.filter(event => event !== eventId)
      } else {
        this.eventDetails.push(eventId)
      }
    },
  },
}
</script>
