export default {
  methods: {
    tripStepEventName(tripStepEvent) {
      let tripStepEventName = tripStepEvent.address.address
      if (tripStepEvent.airport) {
        tripStepEventName = `${tripStepEvent.airport.name} - ${tripStepEvent.airport.icaoCode}`
      } else if (tripStepEvent.address.addressName) {
        tripStepEventName = tripStepEvent.address.addressName
      }

      return tripStepEventName
    },
    tripTransferEventName(tripStepEvent) {
      let tripStepEventName = tripStepEvent.address.address

      if (tripStepEvent.address.postalCode && tripStepEvent.address.postalCode !== '00000') {
        tripStepEventName = `${tripStepEventName}, ${tripStepEvent.address.postalCode}`
      }

      if (tripStepEvent.address.city && tripStepEvent.address.city !== tripStepEvent.address.address) {
        tripStepEventName = `${tripStepEventName}, ${tripStepEvent.address.city}`
      }

      if (tripStepEvent.address.countryName) {
        tripStepEventName = `${tripStepEventName}, ${tripStepEvent.address.countryName}`
      }

      return tripStepEventName
    },
  },
}
