<template>
  <div :id="`${id}-aircraft-people-btn`">
    <b-button v-ripple.400 size="sm" variant="light" v-bind="$attrs" v-on="$listeners" @click="click">
      <font-awesome-icon class="mr-50" icon="users" />
      <span v-if="people.passengers"> {{ people.passengers }} {{ $t('trip.event.pax') }} </span>
      <span v-if="people.passengers && people.crew" class="mx-25"> / </span>
      <span v-if="people.crew"> {{ people.crew }} {{ $tc('trip.event.crew_number', people.crew) }} </span>
      <span v-if="!people.crew && !people.passengers"> {{ $tc('people.no_informations') }} </span>
      <font-awesome-icon v-if="showChevron" :icon="chevronDown ? 'chevron-down' : 'chevron-up'" class="ml-50" />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'AircraftPeopleBtn',

  props: {
    id: {
      type: String,
      default: 'aircraft-people-btn',
    },
    people: {
      type: Object,
      default: () => ({}),
    },
    showChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chevronDown: false,
    }
  },
  methods: {
    click(e) {
      this.showChevron && (this.chevronDown = !this.chevronDown)
      this.$emit('click', e)
    },
  },
}
</script>
<style lang="scss" scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
