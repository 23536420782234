<template>
  <section>
    <div>
      <b-form ref="form" :style="{ height: trHeight, overflow: 'visible' }" class="repeater-form" @submit.prevent="repeatAgain">
        <!-- Row Loop -->
        <b-row v-for="(invoice, index) in invoices" :id="invoice.id" :key="invoice.id" ref="row">
          <!-- Row 1-->
          <b-col cols="12" md="3">
            <b-form-group :label="$t('invoice.internal_invoice_number')" label-for="internalInvoiceNumber">
              <b-form-input :placeholder="$t('invoice.internal_invoice_number')" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group :label="$t('invoice.total_amount_to_paid')" label-for="totalAmountToPaid">
              <b-form-input :placeholder="$t('invoice.total_amount_to_paid')" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group :label="$t('invoice.status.title')" label-for="incvoiceStatus">
              <v-select
                v-model="invoice.statusSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                label="label"
                placeholder="Status"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group :label="$t('invoice.created_at')" label-for="incvoiceDate">
              <b-form-datepicker :id="`datepicker-invoice${index}`" v-model="invoice.dateSelected" :locale="$i18n.locale" :placeholder="$t('common.date')" />
            </b-form-group>
          </b-col>

          <!-- Row 2 -->
          <b-col cols="12" md="3">
            <b-form-group :label="$t('invoice.numbers')" label-for="invoiceNumbers">
              <v-select
                v-model="invoice.invoiceNumbersSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="invoiceNumbersOptions"
                class="w-100"
                label="label"
                multiple
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group :label="$t('timeline.provider.invoice.upload')" label-for="uploadInvoice">
              <div class="text-muted font-small-3">
                <span class="font-weight-bold">{{ $t('dropzone.accepted_format') }}:</span> <span>pdf</span> |
                <span class="font-weight-bold">{{ $t('dropzone.max_file_size') }}:</span> <span>500Ko</span>
              </div>
              <vue-dropzone
                id="dropzoneRecto"
                ref="dropzoneIdentityRecto"
                :options="dropzoneOptionsInvoice"
                class="d-flex justify-content-center align-content-center"
              /> </b-form-group></b-col>

          <b-col class="d-flex align-items-center" cols="12" md="3">
            <b-media-body>
              <h6 class="transaction-title">{{ $t('invoice.total_invoice_amount') }} : {{ 45678 }} $</h6>
              <span>
                <b-button v-b-toggle.sidebar-adjustInvoice v-ripple.400="'rgba(40, 199, 111, 0.15)'" size="sm" variant="outline-success">
                  {{ $t('invoice.adjust_invoice') }}
                </b-button>
              </span>
            </b-media-body>
          </b-col>
          <b-col class="d-flex justify-content-end" cols="12" md="12">
            <b-link>
              <feather-icon
                v-b-tooltip.hover.bottom.v-danger
                class="text-danger"
                icon="Trash2Icon"
                size="21"
                title="Delete quotation"
                @click="modalDeleteInvoice(index)"
              />
            </b-link>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="inline-spacing d-flex justify-content-end">
      <b-button v-ripple.400 @click="repeatAgain">
        <feather-icon class="mr-25" icon="PlusIcon" />
        <span>Add Another invoice</span>
      </b-button>
      <b-button v-ripple.400 @click="showToast('success')">
        <feather-icon class="mr-25" icon="SaveIcon" />
        <span>{{ $t('action.save') }}</span>
      </b-button>
    </div>

    <b-sidebar id="sidebar-adjustInvoice" backdrop right shadow>
      <div class="text-center">
        <b-card-text class="mt-2 h4 color-inherit text-reset"> Regularize </b-card-text>
      </div>

      <div class="d-flex flex-column">
        <b-button v-ripple.400 :to="{ name: 'quotation-custom' }" class="text-center p-1 m-1">
          {{ $t('invoice.regularize.add') }}
        </b-button>

        <b-button v-ripple.400 :to="{ name: 'quotation-custom' }" class="text-center p-1 m-1">
          {{ $t('invoice.regularize.manage') }}
        </b-button>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'InvoiceAdd',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vueDropzone: vue2Dropzone,
  },
  mixins: [heightTransition],
  props: {
    quotations: {
      type: Array,
      default: () => [],
    },
    serviceType: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      dropzoneOptionsInvoice: {
        url: 'https://localhost',
        dictDefaultMessage: this.$t('timeline.provider.invoice.upload'),
        thumbnailWidth: 250,
        /* maxFilesize: 0.5, */
        uploadMultiple: false,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: '.pdf',
        addRemoveLinks: true,
        maxfilesexceeded(file) {
          this.removeAllFiles()
          this.addFile(file)
        },
      },
      file: null,
      fields: ['id', 'date', 'status', 'action'],
      status: [
        {
          1: 'Confirmed',
          2: 'Action required',
          3: 'In progress',
        },
        {
          1: 'light-success',
          2: 'light-danger',
          3: 'light-warning',
        },
      ],
      invoices: [
        {
          id: 1,
          invoiceNumbersSelected: [
            { label: '1256', value: 1256 },
            { label: '1257', value: 1257 },
          ],
          dateSelected: null,
          statusSelected: 'paid',
          prevHeight: 0,
        },
      ],
      nextInvoiceId: 2,
      invoiceNumbersOptions: [
        { label: '1256', value: 1256 },
        { label: '1257', value: 1257 },
        { label: '1258', value: 1258 },
      ],
      statusOptions: [
        { label: this.$t('invoice.status.paid'), value: 'paid' },
        { label: this.$t('invoice.status.to_be_paid'), value: 'toBePaid' },
        { label: this.$t('invoice.status.waiting_quote'), value: 'waitingQuote' },
      ],
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.initTrHeight()
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    modalDeleteInvoice(index) {
      this.$bvModal
        .msgBoxConfirm(this.$t('alert.delete_confirmation.message'), {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeItem(index)
          }
        })
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('alert.save.success'),
          icon: 'CheckCircleIcon',
          text: this.$t('alert.save.success'),
          variant,
        },
      })
    },
    repeatAgain() {
      this.invoices.push({
        id: (this.nextInvoiceId += this.nextInvoiceId),
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.invoices.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.trSetHeight(this.$refs.form.scrollHeight)
        }
      })
    },
  },
}
</script>
