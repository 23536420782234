<template>
  <section>
    <div>
      <b-overlay :show="ordersLoading" spinner-medium>
        <b-card class="mb-0" no-body>
          <app-data-table
            :actions-table="{ showShow: true /* TODO RIGHT */ }"
            :bottom-table="{ totalCount: totalItems }"
            :fields="tableColumns"
            :items="orders"
            :per-page="params.numberOfItemsPerPage"
            :sort-by.sync="sortBy"
            manual-pagination
            table-name="service-list-table"
            @pagination="params.page = $event"
            @show="
              $router.push({
                name: 'OrderViewShow',
                params: { id: $event.item.id },
              })
            "
          >
            <!-- Column: Id -->
            <template #cell(id)="data">
              <span>{{ data.value }}</span>
            </template>

            <template #cell(createdAt)="data">
              <span>{{ data.value | dateFormat }}</span>
            </template>

            <template #cell(priceExcludingTaxes)="data">
              <span>{{ data.value }} {{ $store.state.appConfig.currency }}</span>
            </template>

            <template #cell(priceIncludingTaxes)="data">
              <span>{{ data.value }} {{ $store.state.appConfig.currency }}</span>
            </template>

            <template #cell(state)="{ item }">
              <b-badge v-if="item.cancelled" class="text-capitalize" pill variant="light-danger">
                {{ $t('state.cancelled') }}
              </b-badge>
              <b-badge v-else-if="item.refused" class="text-capitalize" pill variant="light-danger">
                {{ $t('state.refused') }}
              </b-badge>
              <b-badge v-else :variant="`light-${resolveColor(item.state)}`" pill>
                {{ $t('enum.quote_state.' + item.state) }}
              </b-badge>
            </template>
          </app-data-table>
        </b-card>
      </b-overlay>
    </div>
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import { fetchTripStepProviderOrdersRequest } from '@/request/globalApi/requests/tripStepEventRequests'
import AppDataTable from '@/components/AppDataTable.vue'

export default {
  name: 'TimelineStepProviderOrderList',

  components: {
    AppDataTable,
  },
  mixins: [UIHelper],
  props: {
    stepEventId: {
      type: Number,
      required: true,
    },
    providerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id', sortable: false },
        { key: 'createdAt', sortable: false },
        { key: 'priceIncludingTaxes', sortable: false },
        { key: 'priceExcludingTaxes', sortable: false },
        { key: 'state', sortable: false },
      ],
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
      },
      perPageOptions: [5, 10, 25, 50, 100],
      sortBy: '',
      sortDesc: '',
      orders: [],
      totalItems: 0,
      ordersLoading: false,
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
    dataMeta() {
      const localItemsCount = this.orders.length ? this.orders.length : 0
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchOrders()
  },
  methods: {
    APIFetchOrders() {
      this.ordersLoading = true
      fetchTripStepProviderOrdersRequest(this.stepEventId, this.providerId, this.params)
        .then(response => {
          const { orders, totalItems } = response.data
          this.totalItems = totalItems
          this.orders = orders
        })
        .finally(() => {
          this.ordersLoading = false
        })
    },
    refetchData() {
      this.APIFetchOrders()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.actionCol {
  width: 13%;
}
</style>
