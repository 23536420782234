<template>
  <div :id="id" ref="dropzoneElement" :class="{ 'vue-dropzone dropzone': includeStyling }">
    <div v-if="useCustomSlot" class="dz-message">
      <slot>Drop files here to upload</slot>
    </div>
  </div>
</template>

<script>
import Dropzone from 'dropzone' //eslint-disable-line
import '../../node_modules/dropzone/dist/dropzone.css'

Dropzone.autoDiscover = false
export default {
  name: 'AppDropZone',

  props: {
    value: {
      type: Object,
      default: () => ({
        name: null,
      }),
    },
    fileUrlKey: {
      type: String,
      default: 'fileUrl',
    },
    id: {
      type: String,
      required: true,
      default: 'dropzone',
    },
    options: {
      type: Object,
      required: true,
    },
    includeStyling: {
      type: Boolean,
      default: true,
      required: false,
    },
    destroyDropzone: {
      type: Boolean,
      default: true,
      required: false,
    },
    duplicateCheck: {
      type: Boolean,
      default: false,
      required: false,
    },
    useCustomSlot: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      wasQueueAutoProcess: true,
      isMounted: false,
    }
  },

  computed: {
    dropzoneSettings() {
      const defaultValues = {
        thumbnailWidth: 200,
        thumbnailHeight: 200,
      }
      Object.keys(this.options).forEach(function (key) {
        defaultValues[key] = this.options[key]
      }, this)
      return defaultValues
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'value.name': function () {
      if (this.isMounted) this.loadFile()
    },
  },

  mounted() {
    if (this.$isServer && this.hasBeenMounted) {
      return
    }
    this.hasBeenMounted = true
    this.dropzone = new Dropzone(this.$refs.dropzoneElement, this.dropzoneSettings)
    const vm = this
    this.dropzone.on('thumbnail', (file, dataUrl) => {
      vm.$emit('vdropzone-thumbnail', file, dataUrl)
    })
    this.dropzone.on('addedfile', function (file) {
      // let isDuplicate = false
      if (vm.duplicateCheck) {
        if (this.files.length) {
          let _i
          let _len
          for (
            _i = 0, _len = this.files.length;
            _i < _len - 1;
            _i++ // -1 to exclude current file
          ) {
            if (
              this.files[_i].name === file.name
              && this.files[_i].size === file.size
              && this.files[_i].lastModifiedDate.toString() === file.lastModifiedDate.toString()
            ) {
              this.removeFile(file)
              // isDuplicate = true
              vm.$emit('vdropzone-duplicate-file', file)
            }
          }
        }
      }
      // Emit the event only if a file is upload by user, no by a fetch request
      if (!file.manuallyAdded) vm.$emit('vdropzone-file-added', file)
    })
    this.dropzone.on('addedfiles', files => {
      vm.$emit('vdropzone-files-added', files)
    })
    this.dropzone.on('removedfile', file => {
      vm.$emit('vdropzone-removed-file', file)
      if (file.manuallyAdded && vm.dropzone.options.maxFiles !== null) vm.dropzone.options.maxFiles++
    })
    this.dropzone.on('success', (file, response) => {
      vm.$emit('vdropzone-success', file, response)
    })
    this.dropzone.on('successmultiple', (file, response) => {
      vm.$emit('vdropzone-success-multiple', file, response)
    })
    this.dropzone.on('error', (file, message, xhr) => {
      vm.$emit('vdropzone-error', file, message, xhr)
    })
    this.dropzone.on('errormultiple', (files, message, xhr) => {
      vm.$emit('vdropzone-error-multiple', files, message, xhr)
    })
    this.dropzone.on('sending', (file, xhr, formData) => {
      vm.$emit('vdropzone-sending', file, xhr, formData)
    })
    this.dropzone.on('sendingmultiple', (file, xhr, formData) => {
      vm.$emit('vdropzone-sending-multiple', file, xhr, formData)
    })
    this.dropzone.on('complete', file => {
      vm.$emit('vdropzone-complete', file)
    })
    this.dropzone.on('completemultiple', files => {
      vm.$emit('vdropzone-complete-multiple', files)
    })
    this.dropzone.on('canceled', file => {
      vm.$emit('vdropzone-canceled', file)
    })
    this.dropzone.on('canceledmultiple', files => {
      vm.$emit('vdropzone-canceled-multiple', files)
    })
    this.dropzone.on('maxfilesreached', files => {
      vm.$emit('vdropzone-max-files-reached', files)
    })
    this.dropzone.on('maxfilesexceeded', file => {
      vm.$emit('vdropzone-max-files-exceeded', file)
    })
    this.dropzone.on('processing', file => {
      vm.$emit('vdropzone-processing', file)
    })
    this.dropzone.on('processingmultiple', files => {
      vm.$emit('vdropzone-processing-multiple', files)
    })
    this.dropzone.on('uploadprogress', (file, progress, bytesSent) => {
      vm.$emit('vdropzone-upload-progress', file, progress, bytesSent)
    })
    this.dropzone.on('totaluploadprogress', (totaluploadprogress, totalBytes, totalBytesSent) => {
      vm.$emit('vdropzone-total-upload-progress', totaluploadprogress, totalBytes, totalBytesSent)
    })
    this.dropzone.on('reset', () => {
      vm.$emit('vdropzone-reset')
    })
    this.dropzone.on('queuecomplete', () => {
      vm.$emit('vdropzone-queue-complete')
    })
    this.dropzone.on('drop', event => {
      vm.$emit('vdropzone-drop', event)
    })
    this.dropzone.on('dragstart', event => {
      vm.$emit('vdropzone-drag-start', event)
    })
    this.dropzone.on('dragend', event => {
      vm.$emit('vdropzone-drag-end', event)
    })
    this.dropzone.on('dragenter', event => {
      vm.$emit('vdropzone-drag-enter', event)
    })
    this.dropzone.on('dragover', event => {
      vm.$emit('vdropzone-drag-over', event)
    })
    this.dropzone.on('dragleave', event => {
      vm.$emit('vdropzone-drag-leave', event)
    })

    this.loadFile()
    this.isMounted = true
    vm.$emit('vdropzone-mounted')
  },

  beforeDestroy() {
    if (this.destroyDropzone) this.dropzone.destroy()
  },

  methods: {
    loadFile() {
      if (this.value && this.value[this.fileUrlKey]) {
        const file = {
          id: this.value.id || null,
          size: this.value.size || null, // TODO: it displays '0 b'
          name: this.$options.filters.trans(this.value.name) || 'Unknown',
        }
        this.manuallyAddFile(file, this.value[this.fileUrlKey])
      }
    },
    manuallyAddFile(file, fileUrl) {
      file.manuallyAdded = true
      this.dropzone.emit('addedfile', file)
      let containsImageFileType = false
      if (
        fileUrl.indexOf('.svg') > -1
        || fileUrl.indexOf('.png') > -1
        || fileUrl.indexOf('.jpg') > -1
        || fileUrl.indexOf('.jpeg') > -1
        || fileUrl.indexOf('.gif') > -1
        || fileUrl.indexOf('.webp') > -1
      ) containsImageFileType = true
      if (
        this.dropzone.options.createImageThumbnails
        && containsImageFileType
        && file.size <= this.dropzone.options.maxThumbnailFilesize * 1024 * 1024
      ) {
        fileUrl && this.dropzone.emit('thumbnail', file, fileUrl)
        const thumbnails = file.previewElement.querySelectorAll('[data-dz-thumbnail]')
        for (let i = 0; i < thumbnails.length; i++) {
          thumbnails[i].style.width = `${this.dropzoneSettings.thumbnailWidth}px`
          thumbnails[i].style.height = `${this.dropzoneSettings.thumbnailHeight}px`
          thumbnails[i].style['object-fit'] = 'contain'
        }
      }
      this.dropzone.emit('complete', file)
      if (this.dropzone.options.maxFiles) this.dropzone.options.maxFiles--
      this.dropzone.files.push(file)
      this.$emit('vdropzone-file-added-manually', file)
    },
    setOption(option, value) {
      this.dropzone.options[option] = value
    },
    removeAllFiles(bool) {
      this.dropzone.removeAllFiles(bool)
    },
    processQueue() {
      const dropzoneEle = this.dropzone
      //   this.dropzone.processQueue()
      this.dropzone.on('success', () => {
        dropzoneEle.options.autoProcessQueue = true
      })
      this.dropzone.on('queuecomplete', () => {
        dropzoneEle.options.autoProcessQueue = false
      })
    },
    init() {
      return this.dropzone.init()
    },
    destroy() {
      return this.dropzone.destroy()
    },
    updateTotalUploadProgress() {
      return this.dropzone.updateTotalUploadProgress()
    },
    getFallbackForm() {
      return this.dropzone.getFallbackForm()
    },
    getExistingFallback() {
      return this.dropzone.getExistingFallback()
    },
    setupEventListeners() {
      return this.dropzone.setupEventListeners()
    },
    removeEventListeners() {
      return this.dropzone.removeEventListeners()
    },
    disable() {
      return this.dropzone.disable()
    },
    enable() {
      return this.dropzone.enable()
    },
    filesize(size) {
      return this.dropzone.filesize(size)
    },
    accept(file, done) {
      return this.dropzone.accept(file, done)
    },
    addFile(file) {
      return this.dropzone.addFile(file)
    },
    removeFile(file) {
      this.dropzone.removeFile(file)
    },
    getAcceptedFiles() {
      return this.dropzone.getAcceptedFiles()
    },
    getRejectedFiles() {
      return this.dropzone.getRejectedFiles()
    },
    getFilesWithStatus() {
      return this.dropzone.getFilesWithStatus()
    },
    getQueuedFiles() {
      return this.dropzone.getQueuedFiles()
    },
    getUploadingFiles() {
      return this.dropzone.getUploadingFiles()
    },
    getAddedFiles() {
      return this.dropzone.getAddedFiles()
    },
    getActiveFiles() {
      return this.dropzone.getActiveFiles()
    },
    displayExistingFile(file, fileUrl) {
      return this.dropzone.displayExistingFile(file, fileUrl)
    },
  },
}
</script>

<style>
.vue-dropzone {
  background-color: #fff;
  background-clip: padding-box;
  font-family: inherit;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  height: auto;
  line-height: 1.6rem;
  padding: 0.438rem 1rem;
  border-radius: 0.357rem;
  border: 1px solid #d8d6de;
}
.vue-dropzone:hover {
  background-color: #f6f6f6;
}
.vue-dropzone > i {
  color: #ccc;
}
.vue-dropzone > .dz-preview {
  z-index: 10;
}
.vue-dropzone > .dz-preview:hover {
  z-index: 10 !important;
}
.vue-dropzone > .dz-preview .dz-image {
  border-radius: 0;
  width: 100%;
  height: 100%;
}
.vue-dropzone > .dz-preview .dz-image img:not([src]) {
  width: 200px;
  height: 200px;
}
.vue-dropzone > .dz-preview .dz-image:hover img {
  transform: none;
  -webkit-filter: none;
}
.vue-dropzone > .dz-preview .dz-details {
  bottom: 0;
  top: 0;
  color: white;
  background-color: #00cfe8;
  transition: opacity 0.2s linear;
  text-align: left;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename {
  overflow: hidden;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename span,
.vue-dropzone > .dz-preview .dz-details .dz-size span {
  background-color: transparent;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: none;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename:hover span {
  background-color: transparent;
  border: none;
}
.vue-dropzone > .dz-preview .dz-progress .dz-upload {
  background: #cccccc;
}
.vue-dropzone > .dz-preview .dz-remove {
  position: absolute;
  z-index: 30;
  color: white;
  margin-left: 15px;
  padding: 10px;
  top: inherit;
  bottom: 15px;
  border: 2px white solid;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 1.1px;
  opacity: 0;
}
.vue-dropzone > .dz-preview:hover .dz-remove {
  opacity: 1;
}
.vue-dropzone > .dz-preview .dz-progress {
  display: none !important;
}
/* .vue-dropzone > .dz-preview .dz-success-mark {
  display: none !important;
} */
/* .vue-dropzone > .dz-preview .dz-details {
  display: none !important;
} */
.vue-dropzone > .dz-preview .dz-error-mark {
  display: none !important;
}
.vue-dropzone > .dz-preview .dz-error-message {
  display: none !important;
}
.vue-dropzone > .dz-preview .dz-success-mark,
.vue-dropzone > .dz-preview .dz-error-mark {
  margin-left: auto;
  margin-top: auto;
  width: 100%;
  top: 35%;
  left: 0;
}
.vue-dropzone > .dz-preview .dz-success-mark svg,
.vue-dropzone > .dz-preview .dz-error-mark svg {
  margin-left: auto;
  margin-right: auto;
}
.vue-dropzone > .dz-preview .dz-error-message {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  width: 100%;
  text-align: center;
}
.vue-dropzone > .dz-preview .dz-error-message:after {
  display: none;
}
</style>
