<template>
  <section v-if="selectedOrganization.id === tripData.organization.id || selectedOrganization.operator">
    <!--ONE LEG -->
    <div v-for="(tripStep, index) in tripSteps" :key="index + 'all'">
      <b-collapse :id="'allTrip' + tripData.id" type="border">
        <b-row class="match-height">
          <b-col md="10">
            <b-card class="border my-1 rounded overflow-hidden" no-body>
              <trip-leg-resume
                :action-mode="actionMode"
                :trip-data="tripData"
                :trip-step.sync="tripStep"
                @refetch-data="$emit('refetch-data')"
              />
            </b-card>
          </b-col>

          <b-col md="2">
            <b-card v-if="$can('TRIP_ACCOUNTING_VIEW')" class="my-1 p-50 border" no-body>
              <trip-leg-cost :trip-data="tripData" :trip-step="tripStep" :disabled="!charges" />
            </b-card>
          </b-col>
        </b-row>
        <!-- COST DETAILS -->
        <trip-leg-cost-details v-if="$can('TRIP_ACCOUNTING_VIEW')" :trip-step="tripStep" :charges.sync="charges" />
        <!-- COST DETAILS -->
      </b-collapse>
    </div>
    <!--MULTI LEG-->
    <div v-for="(tripStep, index) in tripSteps" :key="index">
      <b-collapse :id="'collapse-trip_details' + tripData.id + '-' + tripStep.id" type="border">
        <b-row class="match-height">
          <b-col md="10">
            <b-card class="border my-1 rounded overflow-hidden" no-body>
              <trip-leg-resume
                :action-mode="actionMode"
                :trip-data="tripData"
                :trip-step.sync="tripStep"
              />
            </b-card>
          </b-col>

          <b-col md="2">
            <b-card class="my-1 p-50 border" no-body>
              <trip-leg-cost :trip-data="tripData" :trip-step="tripStep" :disabled="!charges" />
            </b-card>
          </b-col>
        </b-row>

        <!-- COST DETAILS -->
        <trip-leg-cost-details :trip-step="tripStep" :charges.sync="charges" />
        <!-- COST DETAILS -->
      </b-collapse>
    </div>
  </section>

  <!-- -------------------- FBO -------------------- -->
  <section v-else>
    <!--ONE LEG -->
    <div v-for="(tripStepEvent, index) in tripStepEvents[tripData.id]" :key="index + 'all'">
      <b-collapse :id="'allTrip' + tripData.id" type="border">
        <b-row class="match-height">
          <b-col md="10">
            <b-card class="border my-1 rounded overflow-hidden" no-body>
              <trip-leg-resume
                :action-mode="actionMode"
                :trip-data="tripData"
                :trip-step="tripStepEvent.tripStep"
                :trip-step-event.sync="tripStepEvent"
                @refetch-data="$emit('refetch-data')"
              />
            </b-card>
          </b-col>

          <b-col md="2">
            <b-card class="my-1 p-50 border" no-body>
              <trip-leg-cost
                :trip-data="tripData"
                :trip-step="tripStepEvent.tripStep"
                :trip-step-event="tripStepEvent"
                :disabled="!charges"
              />
            </b-card>
          </b-col>
        </b-row>

        <!-- COST DETAILS -->
        <trip-leg-cost-details :trip-step="tripStepEvent.tripStep" :charges.sync="charges" />
        <!-- COST DETAILS -->
      </b-collapse>
    </div>
    <!--MULTI LEG-->
    <div v-for="(tripStepEvent, index) in tripStepEvents[tripData.id]" :key="index">
      <b-collapse :id="'collapse-trip_details' + tripData.id + '-' + tripStepEvent.tripStep.id" type="border">
        <b-row class="match-height">
          <b-col md="10">
            <b-card class="border my-1 rounded overflow-hidden" no-body>
              <trip-leg-resume
                :action-mode="actionMode"
                :trip-data="tripData"
                :trip-step="tripStepEvent.tripStep"
                :trip-step-event.sync="tripStepEvent"
              />
            </b-card>
          </b-col>

          <b-col md="2">
            <b-card class="my-1 p-50 border" no-body>
              <trip-leg-cost
                :trip-data="tripData"
                :trip-step="tripStepEvent.tripStep"
                :trip-step-event="tripStepEvent"
                :disabled="!charges"
              />
            </b-card>
          </b-col>
        </b-row>
        <!-- COST DETAILS -->
        <trip-leg-cost-details :trip-step="tripStepEvent.tripStep" :charges.sync="charges" />
        <!-- COST DETAILS -->
      </b-collapse>
    </div>
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import TripLegResume from '@/views/trip/leg/TripLegResume.vue'
import TripLegCost from '@/views/trip/leg/TripLegCost.vue'
import TripLegCostDetails from '@/views/trip/leg/TripLegCostDetails.vue'
import { mapState } from 'vuex'

export default {
  name: 'TripLegCollapsible',

  components: {
    TripLegCostDetails,
    TripLegCost,
    TripLegResume,
  },
  mixins: [UIHelper],
  props: {
    actionMode: {
      type: Boolean,
      default: true,
    },
    tripData: {
      type: Object,
      default: () => ({}),
    },
    tripSteps: {
      type: Array,
      default: () => [],
    },
    tripStepEvents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      charges: null,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
}
</script>
