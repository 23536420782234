<template>
  <b-sidebar id="sidebar-editLeg" :visible="isEditLegSidebarActive" lasy no-header @change="val => $emit('update:is-edit-leg-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 ppr-no-print">
          {{ $t('trip.action.edit_leg') }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <b-card-body v-show="!legEdition">
        <div v-if="$can('TRIP_EDIT')">
          <!-- EDIT LEG -->
          <b-button v-ripple.400 class="mt-1 btn btn-block" @click.prevent="editEventView()">
            <font-awesome-icon class="mr-50" icon="edit" />
            {{ $t('trip.action.edit_leg_intent') }}
          </b-button>
          <p class="mt-1">{{ $t('common.notice') }} {{ $t('trip.action.edit_leg_note') }}</p>
          <p class="mt-1">
            <u>{{ $t('common.warning') }} :</u>
          </p>
          <p>{{ $t('trip.action.edit_leg_warning') }}</p>

          <!-- EDIT AIRPORT -->
          <div v-if="stepTrip.className === 'TripFlight'">
            <b-button
              v-ripple.400
              :to="{
                name: 'airport-map',
                params: {
                  trip_id: $parent.$route.params.trip_id,
                  event_id: stepTrip.id,
                  leg_id: stepTrip.flightDeparture.id,
                },
              }"
              class="mt-3 btn btn-block"
            >
              <font-awesome-icon class="mr-50" icon="plane" />
              {{ $t('trip.action.edit_airports') }}
            </b-button>
            <p class="mt-1">{{ $t('common.notice') }} {{ $t('trip.action.edit_airports_note') }}</p>
            <p class="mt-1">
              <u>{{ $t('common.warning') }} :</u>
            </p>
            <p>{{ $t('trip.action.edit_airports_warning') }}</p>
          </div>
        </div>

        <!-- DELETE LEG -->
        <div v-if="$can('TRIP_DELETE')">
          <b-button v-ripple.400 class="mt-3 btn btn-block" variant="danger" @click.prevent="confirmLegDelete">
            <font-awesome-icon class="mr-50" icon="ban" />
            {{ $t('trip.action.edit_leg_cancel') }}
          </b-button>
          <p class="mt-1">
            <u>{{ $t('common.warning') }} :</u>
          </p>
          <p>{{ $t('trip.action.edit_leg_cancel_warning') }}</p>
        </div>
      </b-card-body>

      <!-- Form EDIT FLIGHT LEG -->
      <b-card-body v-show="legEdition">
        <div class="d-flex justify-content-start align-items-center mb-3">
          <feather-icon class="ml-1 cursor-pointer" icon="ArrowLeftIcon" size="16" @click.prevent="editEventView()" />
          <h4 class="mb-0 ml-3">{{ $t('trip.action.edit_event') }}</h4>
        </div>
        <validation-observer ref="addOrganizationUser" #default="{ handleSubmit }">
          <!-- Form -->
          <b-form v-if="stepTrip.className === 'TripFlight'" @submit.prevent="handleSubmit(patchFlightLeg)">
            <section>
              <b-row>
                <!--PASSENGERS NUMBER-->
                <b-col md="6">
                  <validation-provider #default="{ errors }" :name="$tc('trip.event.passenger_number', stepTrip.passengers)" rules="">
                    <b-form-group :label="$tc('trip.event.passenger_number', stepTrip.passengers)" label-for="passenger-transfer-number">
                      <spin-input id="'passenger-transfer-number" :value="passengersNumber ? passengersNumber : 0" @input="passengersNumber = $event" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider #default="{ errors }" :name="$tc('trip.event.crew_number', crewsNumber)" rules="">
                    <b-form-group :label="$tc('trip.event.crew_number', crewsNumber)" label-for="crew-number">
                      <spin-input id="'crew-number" :value="crewsNumber ? crewsNumber : 0" @input="crewsNumber = $event" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr v-if="false" class="my-2" />
              <b-row>
                <b-col md="12">
                  <!-- Event type -->
                  <validation-provider #default="{ errors }" :name="$t('trip.event.flight_type')" class="validation-required" rules="required">
                    <b-form-group :label="$t('trip.event.flight_type')" :state="errors.length > 0 ? false : null" label-for="event-flightType">
                      <v-select
                        id="event-flightType"
                        v-model="stepTrip.flightType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="FlightType"
                        :reduce="item => item.value"
                        label="value"
                      >
                        <template #option="{ value }">
                          {{ value | capitalize }}
                        </template>
                        <template #selected-option="{ value }">
                          {{ value | capitalize }}
                        </template>
                        <template #no-options> {{ $t('common.no_option_found') }} </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <!-- Event type -->
                  <validation-provider #default="{ errors }" :name="$t('trip.event.flight_mission')" class="validation-required" rules="required">
                    <b-form-group :label="$t('trip.event.flight_mission')" :state="errors.length > 0 ? false : null" label-for="departure">
                      <v-select
                        id="event-flightType"
                        v-model="stepTrip.flightMission"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="FlightMission"
                        :reduce="item => item.value"
                        label="value"
                      >
                        <template #option="{ value }">
                          {{ value | capitalize }}
                        </template>
                        <template #selected-option="{ value }">
                          {{ value | capitalize }}
                        </template>
                        <template #no-options> {{ $t('common.no_option_found') }} </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </section>
            <div class="d-flex mt-2 justify-content-end flex-column">
              <b-button block class="mr-2" type="submit">
                <font-awesome-icon class="mr-50" icon="calendar-plus" />
                {{ $t('trip.event.validate_trip_timeline') }}
              </b-button>
            </div>
          </b-form>

          <b-form v-if="stepTrip.className === 'TripTransfer'">
            <section>
              <hr class="my-1" />

              <!--Departure-->
              <validation-provider class="validation-required">
                <b-form-group :label="$t('trip.event.departure')" label-for="departure-city">
                  <GmapAutocomplete
                    id="departure-city"
                    ref="gmapDeparture"
                    class="form-control"
                    :value="transferLeg.departureAddress ? getDetailedAddress(transferLeg.departureAddress) : null"
                    @place_changed="setTransferDeparturePlace"
                  />
                  <small v-show="!isValidDepartureAddress" class="text-danger">{{
                    $t('trip.event.departure_address_error')
                  }}</small>
                </b-form-group>
              </validation-provider>

              <b-row>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('trip.event.departure_date')"
                    class="validation-required"
                    rules="required"
                  >
                    <b-form-group :label="$t('trip.event.departure_date')" class="mb-1" label-for="departure-date">
                      <b-form-datepicker
                        id="departure-date"
                        v-model="transferLeg.departure.date"
                        :locale="$i18n.locale"
                        :max="transferLeg.arrival.date"
                        :min="new Date()"
                        :state="errors.length > 0 ? false : null"
                        size="md"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('trip.event.etd_utc')"
                    class="validation-required"
                    rules="required"
                  >
                    <b-form-group :label="$t('trip.event.etd_utc')" class="mb-50" label-for="departure-time">
                      <b-form-timepicker
                        v-model="transferLeg.departure.time"
                        :state="errors.length > 0 ? false : null"
                        size="md"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <span v-if="transferLeg.departure.date && transferLeg.departure.time && transferLeg.departureAddress && transferLeg.departureAddress.timeZone" class="ml-25">
                    {{ $t('common.local_time') }} :
                    {{
                      $moment
                        .utc(transferLeg.departure.date + ' ' + transferLeg.departure.time)
                        .utcOffset(transferLeg.departureAddress.timeZone)
                        .format('ddd, MMM Do YYYY HH:mm')
                    }}
                  </span>
                </b-col>
              </b-row>

              <hr class="my-2" />

              <!-- ARRIVAL -->
              <b-form-group :label="$t('trip.event.arrival')" label-for="arrival-city">
                <GmapAutocomplete
                  id="arrival-city"
                  ref="gmapArrival"
                  class="form-control"
                  :value="transferLeg.arrivalAddress ? getDetailedAddress(transferLeg.arrivalAddress) : null"
                  @place_changed="setTransferArrivalPlace"
                />
                <small v-show="!isValidArrivalAddress" class="text-danger">{{
                  $t('trip.event.arrival_address_error')
                }}</small>
              </b-form-group>

              <hr class="my-2" />

              <section>
                <b-row>
                  <!-- Event type -->
                  <b-col cols="12">
                    <enum-select
                      v-model="transferLeg.engineType"
                      :label="$t('trip.event.transfer_helicopter.engine_type')"
                      enum-class="EngineType"
                      input-id="engineType"
                      required
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <!--PASSENGERS NUMBER-->
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$tc('trip.event.passenger_number', transferLeg.passengers)"
                    >
                      <b-form-group
                        :label="$tc('trip.event.passenger_number', transferLeg.passengers)"
                        label-for="passengerFlightNumber"
                      >
                        <spin-input
                          id="'passengerFlightNumber"
                          :value="transferLeg.passengers ? transferLeg.passengers : 0"
                          @input="transferLeg.passengers = $event"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <!--LUGGAGE NUMBER-->
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      :name="$tc('trip.event.luggage_number', transferLeg.luggage)"
                    >
                      <b-form-group
                        :label="$tc('trip.event.luggage_number', transferLeg.luggage)"
                        label-for="luggage-number"
                      >
                        <spin-input
                          id="'luggage-number"
                          :value="transferLeg.luggage ? transferLeg.luggage : 0"
                          @input="transferLeg.luggage = $event"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!--BAGS NUMBER-->
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      :name="$tc('trip.event.bag_number', transferLeg.bags)"
                    >
                      <b-form-group
                        :label="$tc('trip.event.bag_number', transferLeg.bags)"
                        label-for="bag-number"
                      >
                        <spin-input
                          id="'bag-number"
                          :value="transferLeg.bags ? transferLeg.bags : 0"
                          @input="transferLeg.bags = $event"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </section>
            </section>

            <b-button
              :disabled="(!transferLeg.arrivalAddress && !transferLeg.arrivalAddressInline) || (!transferLeg.departureAddress && !transferLeg.departureAddressInline)"
              block
              class="mr-2"
              type="submit"
              @click.prevent="
                validationForm()
                legEdition = false
              "
            >
              <font-awesome-icon class="mr-50" icon="calendar-plus" />
              {{ $t('trip.event.validate_trip_timeline') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              block
              variant="outline-secondary"
              @click="
                hide()
                resetAll()
              "
            >
              <font-awesome-icon class="mr-50" icon="ban" />
              {{ $t('action.cancel') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card-body>
    </template>
  </b-sidebar>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import SpinInput from '@/components/SpinInput.vue'

import {
  deleteTripStepRequest,
  patchTripStepFlightRequest,
  patchTripStepTransferRequest,
} from '@/request/globalApi/requests/tripStepRequests'
import { intersection } from 'lodash'

export default {
  components: { SpinInput },
  model: {
    prop: 'isEditLegSidebarActive',
    event: 'update:is-edit-leg-sidebar-active',
  },
  props: {
    stepEvent: {
      type: Object,
      default: () => ({}),
    },
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
    isEditLegSidebarActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      legEdition: false,
      passengersNumber: 0,
      crewsNumber: 0,
      deleteInput: '',
      deleteState: null,
      transferLeg: {
        bags: 0,
        luggage: 0,
        passengersNumber: 0,
        arrival: {
          date: null,
          time: null,
        },
        arrivalAddressInline: null,
        arrivalAddress: null,
        departure: {
          date: null,
          time: null,
        },
        departureAddressInline: null,
        departureAddress: null,
        engineType: null,
      },
      isValidArrivalAddress: true,
      isValidDepartureAddress: true,
    }
  },
  watch: {
    isEditLegSidebarActive(val) {
      if (val) {
        window.dispatchEvent(new Event('resize'))
      }
    },
  },
  computed: { ...mapState('enum', ['FlightType', 'FlightMission']) },
  mounted() {
    this.fetchEnum2('FlightType')
    this.fetchEnum2('FlightMission')
    this.passengersNumber = this.stepTrip.passengers
    this.crewsNumber = this.stepTrip.crew
    if (this.stepTrip.className === 'TripTransfer') {
      this.copyTransferData()
    }
  },
  methods: {
    ...mapActions('enum', ['fetchEnum2']),

    /* CALL API */
    patchFlightLeg() {
      const stepTripId = this.$parent.$parent.$attrs.id.split('-')[1]
      const leg = {
        passengers: this.passengersNumber,
        crew: this.crewsNumber,
        flightType: this.stepTrip.flightType,
        flightMission: this.stepTrip.flightMission,
      }
      patchTripStepFlightRequest(stepTripId, leg).then(response => {
        this.stepTrip.passengers = this.passengersNumber
        this.stepTrip.crew = this.crewsNumber
        this.legEdition = false
        this.$parent.isEditLegSidebarActive = false
      })
    },
    patchTransferLeg() {
      const stepTripId = this.$parent.$parent.$attrs.id.split('-')[1]
      this.transferLeg.departureDateTime = `${this.transferLeg.departure.date} ${this.transferLeg.departure.time}`
      this.transferLeg.arrivalDateTime = `${this.transferLeg.arrival.date} ${this.transferLeg.arrival.time}`
      patchTripStepTransferRequest(stepTripId, this.transferLeg).then(response => {
        this.stepTrip.passengers = this.transferLeg.passengers
        this.stepTrip.bags = this.transferLeg.bags
        this.stepTrip.luggage = this.transferLeg.luggage
        this.stepTrip.transferDeparture.address = this.transferLeg.departureAddress
        this.stepTrip.transferDeparture.eventDateTime = this.transferLeg.departureDateTime
        this.stepTrip.transferArrival.address = this.transferLeg.arrivalAddress
        this.stepTrip.transferArrival.eventDateTime = this.transferLeg.arrivalDateTime
        this.$parent.isEditLegSidebarActive = false
        this.legEdition = false
      })
    },
    confirmLegDelete() {
      const stepTripId = this.$parent.$parent.$attrs.id.split('-')[1]
      deleteTripStepRequest(stepTripId).then(response => {
        this.$router.push({ name: 'trips' })
      })
    },

    editEventView() {
      this.legEdition = !this.legEdition
    },
    setTransferDeparturePlace(place) {
      this.transferLeg.departureAddressInline = null
      this.transferLeg.departureAddress = null
      this.setAddress(place, 'departure')
    },
    setTransferArrivalPlace(place) {
      this.transferLeg.arrivalAddressInline = null
      this.transferLeg.arrivalAddress = null
      this.setAddress(place, 'arrival')
    },
    copyTransferData() {
      this.transferLeg.bags = this.stepTrip.bags
      this.transferLeg.luggage = this.stepTrip.luggage
      this.transferLeg.passengers = this.stepTrip.passengers
      this.transferLeg.engineType = this.stepTrip.engineType
      this.transferLeg.arrivalAddress = this._cloneDeep(this.stepTrip.transferArrival.address)
      this.transferLeg.departureAddress = this._cloneDeep(this.stepTrip.transferDeparture.address)
      this.transferLeg.departure = {
        date: this.$moment(this.stepTrip.transferDeparture.eventDateTime).format('YYYY-MM-DD'),
        time: this.$moment(this.stepTrip.transferDeparture.eventDateTime).format('HH:mm:ss'),
      }
      this.transferLeg.arrival = {
        date: this.$moment(this.stepTrip.transferArrival.eventDateTime).format('YYYY-MM-DD'),
        time: this.$moment(this.stepTrip.transferArrival.eventDateTime).format('HH:mm:ss'),
      }
    },
    resetAll() {
      this.legEdition = false
      this.copyTransferData()
      requestAnimationFrame(() => {
        if (this.$refs.addOrganizationUser) {
          this.$refs.addOrganizationUser.reset()
        }
      })
    },
    validationForm() {
      this.$refs.addOrganizationUser.validate().then(success => {
        if (success) {
          this.patchTransferLeg()
        }
      })
    },
    setAddress(place, type) {
      let streetNumber = null
      let route = null
      let postalCode = null
      let country = null
      let locality = null
      let address = null
      const timeZone = place.utc_offset_minutes

      if (place.types.includes('airport')) locality = 'airport'

      place.address_components.forEach(addr => {
        if (addr.types.includes('street_number')) streetNumber = addr.short_name
        else if (addr.types.includes('route')) route = addr.short_name
        else if (addr.types.includes('postal_code')) postalCode = addr.short_name
        else if (addr.types.includes('country')) country = addr.short_name
        else if (intersection(addr.types, ['locality', 'postal_town', 'sublocality_level_1', 'route']).length) {
          locality = addr.short_name
        }
      })

      if (route) {
        streetNumber ? (address = `${streetNumber} ${route}`) : (address = route)
      } else {
        address = place.name
      }

      if (address && country && locality) {
        if (type === 'departure') {
          this.isValidDepartureAddress = true
          this.transferLeg.departureAddress = {
            address,
            postalCode: postalCode ?? '00000',
            city: locality,
            countryCode: country,
            timeZone,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          }
          this.transferLeg.departureAddressInline = place.formatted_address
          this.transferLeg.departureLatitude = place.geometry.location.lat()
          this.transferLeg.departureLongitude = place.geometry.location.lng()
        } else {
          this.isValidArrivalAddress = true
          this.transferLeg.arrivalAddress = {
            address,
            postalCode: postalCode ?? '00000',
            city: locality,
            countryCode: country,
            timeZone,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          }
          this.arrivalAddressInline = place.formatted_address
          this.arrivalLatitude = place.geometry.location.lat()
          this.arrivalLongitude = place.geometry.location.lng()
        }
      } else if (type === 'departure') {
        this.isValidDepartureAddress = false
      } else {
        this.isValidArrivalAddress = false
      }
    },
    getDetailedAddress(address) {
      return `${address.address}, ${address.postalCode !== '00000' ? address.postalCode : ''} ${address.city}, ${address.countryName}`
    },
  },
}
</script>
