<template>
  <section>
    <div>
      <b-overlay :show="quoteLinesLoading" spinner-medium>
        <b-card no-body class="mb-0">
          <b-table
            ref="refServiceListTable"
            class="position-relative"
            :items="quoteLines"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="$t('common.no_record_found')"
          >
            <!-- Column: Id -->
            <template #cell(id)="data">
              <b-link :to="{ name: 'OrderViewShow', params: { id: data.item.quoteId } }">
                {{ data.value }}
              </b-link>
            </template>
            <template #cell(name)="data">
              <span>
                {{ data.value }}
              </span>
            </template>

            <template #cell(mainCategoryName)="data">
              <span v-if="data">
                {{ data.value | trans }}
              </span>
            </template>

            <template #cell(status)="data">
              <b-badge pill :variant="`light-${resolveQuoteLineStateColor(data.item.status)}`" class="text-capitalize">
                {{ $t('quote.quoteLine.status.' + data.item.status) }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
        <!-- PAGINATION -->
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span
                class="text-muted"
              >{{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }} {{
                dataMeta.of
              }}
                {{ $tc('pagination.entries', dataMeta.of) }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="params.page"
                :total-rows="totalItems"
                :per-page="params.numberOfItemsPerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        <!-- PAGINATION -->
      </b-overlay>
    </div>
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import { fetchTripStepProviderQuoteLinesRequest } from '@/request/globalApi/requests/tripStepEventRequests'

export default {
  name: 'TimelineStepProviderServiceList',

  mixins: [UIHelper],
  props: {
    stepEvent: {
      type: Object,
    },
    stepTrip: {
      type: Object,
    },
    providerId: {
      type: Number,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id', label: this.$t('common.id'), sortable: false },
        { key: 'name', label: this.$t('timeline.provider.quote_line.name'), sortable: true },
        { key: 'mainCategoryName', label: this.$t('timeline.provider.quote_line.main_category_name'), sortable: true },
        { key: 'status', label: this.$t('common.status'), sortable: false },
      ],
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
      },
      perPageOptions: [5, 10, 25, 50, 100],
      sortBy: '',
      sortDesc: '',
      quoteLines: [],
      totalItems: 0,
      quoteLinesLoading: false,
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
    dataMeta() {
      const localItemsCount = this.quoteLines.length ? this.quoteLines.length : 0
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        getAllItems: this.params.getAllItems,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  activated() {
    this.APIFetchQuoteLines()
  },
  methods: {
    APIFetchQuoteLines() {
      this.quoteLinesLoading = true
      fetchTripStepProviderQuoteLinesRequest(this.stepEvent.id, this.providerId, this.params)
        .then(response => {
          const { quoteLines, totalItems } = response.data
          this.totalItems = totalItems
          this.quoteLines = quoteLines
        })
        .finally(() => {
          this.quoteLinesLoading = false
        })
    },
    refetchData() {
      this.APIFetchQuoteLines()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.actionCol {
  width: 13%;
}
</style>
