<template>
  <section>
    <div class="d-flex justify-content-between align-items-center flex-sm-row flex-column flex-wrap mb-1 mb-sm-50 mr-1">
      <!-- Data resume -->
      <div class="d-flex align-items-center">
        <!-- Title -->
        <div class="font-small-4 font-weight-bold mr-1">
          <span>{{ $t('trip.event.departure') }} </span>
          <span>{{ $t('common.from') }} {{ stepEvent.transferDeparture.address.addressName || stepEvent.transferDeparture.address.address }} </span>
        </div>

        <!-- Event date time -->
        <section class="d-flex align-items-center">
          <div>
            <span class="font-small-3 mr-50">{{ $t('trip.event.etd') }} :</span>
            <span class="mr-50">
              <font-awesome-icon icon="calendar-alt" />
              {{ $moment(stepTrip.transferDeparture.eventDateTime).format('ddd, MMM Do YYYY') }}
            </span>
            <span class="mr-50">
              <font-awesome-icon icon="clock" />
              {{ $moment(stepTrip.transferDeparture.eventDateTime).format('HH:mm') }}
              {{ $t('common.utc') }}
            </span>
          </div>
        </section>
      </div>

      <!-- Edit leg -->
      <div>
        <b-button
          v-if="$can('TRIP_EDIT') || $can('TRIP_DELETE')"
          v-ripple.400
          class="mr-1"
          size="sm"
          @click="isEditLegSidebarActive = !isEditLegSidebarActive"
        >
          <font-awesome-icon class="mr-50" icon="edit" />
          {{ $t('trip.action.edit_leg') }}
        </b-button>
      </div>
    </div>

    <div class="d-flex my-50">
      <AircraftTransferPeopleBtn :id="`timeline-step-transfer-${stepTrip.id}`" :people="stepTrip" class="mr-1" />
      <AircraftPeopleLuggageBtn :id="`timeline-step-transfer-${stepTrip.id}`" :luggage="stepTrip" />
    </div>
    <div class="d-flex my-50">
      <b-button
        v-if="$can('TRIP_EDIT')"
        v-ripple.400
        :to="{
          name: 'TimelineStepTransferHelicopterOrganizations',
          params: {
            trip_id: $route.params.trip_id,
            step_id: stepTrip.id,
            event_id: stepEvent.transferDeparture.id,
          },
        }"
        class="mr-1"
        size="sm"
      >
        {{ $t('timeline.helicopter.search') }}
      </b-button>
      <b-button
        v-if="stepTrip.canDefineHelicopter"
        v-ripple.400
        class="mr-1"
        size="sm"
        @click="isAssignHelicopterSidebarActive = !isAssignHelicopterSidebarActive"
      >
        {{ $t('timeline.helicopter.assign') }}
      </b-button>
    </div>
    <section>
      <b-card-text>
        <div v-if="stepEvent.transferDeparture.address">
          {{ stepEvent.transferDeparture.address.addressName }}
        </div>
        <div v-if="stepEvent.transferDeparture.address.zipcode">
          <span>{{ stepEvent.transferDeparture.address.zipcode }} - </span>
          <span>{{ stepEvent.transferDeparture.address.addressName }}</span>
        </div>
      </b-card-text>

      <b-card-text v-if="stepEvent.transferDeparture.passengers">
        <b-button v-ripple.400="'rgba(220, 193, 129, 0.15)'" v-b-toggle variant="outline-primary" size="sm" :href="'#collapse-' + stepEvent.id" @click.prevent>
          <font-awesome-icon icon="users" class="mr-50" />
          {{ stepEvent.passengers.length }} {{ $tc('trip.event.passenger_number', stepEvent.passengers.length) }}
        </b-button>
      </b-card-text>
      <b-collapse v-if="stepEvent.passengers" :id="'collapse-' + stepEvent.id" type="border">
        <trip-passengers :step-trip="stepTrip" :step-event="stepEvent" />
      </b-collapse>
    </section>
    <!--LIST PROVIDER-->
    <div v-if="displayProvider && stepEvent.className === 'TripTransfer' && stepEvent.transferDeparture.tripStepProviders" class="mt-1">
      <timeline-step-provider
        v-for="provider in stepEvent.transferDeparture.tripStepProviders"
        :key="provider.id"
        :step-event="stepEvent.transferDeparture"
        :trip-data="tripData"
        :step-provider="provider"
      ></timeline-step-provider>
    </div>

    <sidebar-edit-leg :is-edit-leg-sidebar-active.sync="isEditLegSidebarActive" :step-event="stepEvent" :step-trip="stepTrip" />

    <sidebar-assign-helicopter v-if="stepTrip.canDefineHelicopter" :is-assign-helicopter-sidebar-active.sync="isAssignHelicopterSidebarActive" :step-trip="stepTrip" />
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import TripPassengers from '@/views/timeline/step/participant/TimelineStepParticipant.vue'
import AircraftTransferPeopleBtn from '@/components/AircraftTransferPeopleBtn.vue'
import AircraftPeopleLuggageBtn from '@/components/AircraftPeopleLuggageBtn.vue'
import TimelineStepProvider from '@/views/timeline/step/provider/TimelineStepProvider.vue'
import SidebarEditLeg from '@/views/timeline/sidebar/SidebarEditLeg.vue'
import SidebarAssignHelicopter from '@/views/timeline/sidebar/SidebarAssignHelicopter.vue'

export default {
  name: 'TimelineStepTransfer',

  components: {
    TripPassengers,
    AircraftTransferPeopleBtn,
    AircraftPeopleLuggageBtn,
    TimelineStepProvider,
    SidebarEditLeg,
    SidebarAssignHelicopter,
  },
  mixins: [UIHelper],
  props: {
    stepType: {
      type: String,
      default: null,
    },
    tripData: {
      type: Object,
      default: () => ({}),
    },
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
    stepEvent: {
      type: Object,
      default: () => ({}),
    },
    displayProvider: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      eventDetails: [],
      isEditLegSidebarActive: false,
      isAssignHelicopterSidebarActive: false,
    }
  },
  methods: {
    toggleEventDetails(eventId) {
      if (this.eventDetails.includes(eventId)) {
        this.eventDetails = this.eventDetails.filter(event => event !== eventId)
      } else {
        this.eventDetails.push(eventId)
      }
    },
  },
}
</script>
