<template>
  <div v-if="tripData">
    <!--TRIP-->
    <trip-view-flight
      v-if="!flightEdit"
      :flight-edit.sync="flightEdit"
      :trip-data="tripData"
    />
    <trip-edit-flight
      v-else
      :flight-edit.sync="flightEdit"
      :trip-data="tripData"
    />
  </div>
</template>

<script>
import TripViewFlight from '@/views/trip/trip-view/TripViewFlight.vue'
import TripEditFlight from '@/views/trip/trip-edit/TripEditFlight.vue'

export default {
  name: 'TripView',

  components: {
    TripEditFlight,
    TripViewFlight,
  },
  props: {
    tripData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      flightEdit: false,
      aircraftEdit: false,
    }
  },
}
</script>
