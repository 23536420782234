<template>
  <div
    v-if="tripStep && (selectedOrganization.id === tripData.organization.id || selectedOrganization.operator)"
    id="trip-leg-resume"
    class="d-flex h-100"
  >
    <!-- ///// DEPARTURE ///// -->
    <b-col
      :md="actionMode ? 3 : 4"
      class="border-right"
    >
      <section v-if="tripStep.flightDeparture" class="p-50 font-weight-bold">
        <span class="d-flex align-items-center mb-50">
          <font-awesome-icon
            :icon="!tripStep.engineType ? 'plane-departure' : 'helicopter'"
            class="mr-50"
            size="1x"
          />
          <span>
            {{ tripStepEventName(tripStep.flightDeparture) }}
          </span>
        </span>

        <!--ADDRESS / SLOT-->
        <span>
          <div v-if="!tripStep.engineType && tripStep.flightDeparture.slotStatus">
            <span class="font-small-3 mr-50">{{ $t('trip.event.slot.title') }} : </span>
            <b-badge
              v-if="tripStep.flightDeparture.slotStatus"
              :variant="`${resolveSlotStatusVariant(tripStep.flightDeparture.slotStatus)}`"
              class="font-small-3 mr-50"
            >
              {{ tripStep.flightDeparture.slotStatus | enumTranslate('slot_status') }}
            </b-badge>
          </div>
        </span>
      </section>

      <section v-if="tripStep.transferDeparture" class="p-50 font-weight-bold">
        <span class="d-flex align-items-center mb-50">
          <font-awesome-icon
            icon="helicopter"
            class="mr-50"
            size="1x"
          />
          <span>
            {{ tripTransferEventName(tripStep.transferDeparture) }}
          </span>
        </span>
      </section>

      <!--TIME ETD-->
      <section class="p-50">
        <div class="font-weight-bold d-flex align-items-center">
          <font-awesome-icon
            class="mr-50"
            icon="clock"
            size="1x"
          />
          <span class="font-weight-bold">{{ $t('trip.event.etd') }}</span>
        </div>
        <!--TIMEZONE-->
        <!--NO SLOT YET-->
        <div v-if="tripStep.className === 'TripTransfer' || (tripStep.flightDeparture && !tripStep.flightDeparture.startsAt)">
          <div class="py-25">
            <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
            <b-badge variant="light-secondary">
              {{
                tripStep.className === 'TripTransfer' ? tripStep.transferDeparture.eventDateTime : tripStep.flightDeparture.eventDateTime | moment("ddd, MMM Do YYYY")
              }}
            </b-badge>
          </div>
          <div>
            <div class="py-25">
              <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
              <b-badge variant="light-secondary">
                {{
                  tripStep.className === 'TripTransfer' ? $moment(tripStep.transferDeparture.eventDateTime).utc().format('HH:mm') : $moment(tripStep.flightDeparture.eventDateTime).utc().format('HH:mm')
                }}
              </b-badge>
            </div>
            <div v-if="tripStep.flightDeparture && tripStep.flightDeparture.airport" class="py-25">
              <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
              <b-badge variant="light-secondary">
                {{
                  $moment.utc(tripStep.flightDeparture.eventDateTime).utcOffset(tripStep.flightDeparture.airport.timeZone).format('ddd, MMM Do YYYY HH:mm')
                }}
              </b-badge>
            </div>
          </div>
        </div>
        <!--SLOT ADDED-->
        <div
          v-else-if="(tripStep.className === 'TripFlight' && tripStep.flightDeparture) || (tripStep.className === 'TripTransfer' && tripStep.transferDeparture)"
        >
          <div class="py-25">
            <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
            <b-badge variant="light-secondary">
              {{
                tripStep.className === 'TripFlight' && tripStep.flightDeparture ?
                  tripStep.flightDeparture.startsAt :
                  tripStep.transferDeparture.startsAt | moment("ddd, MMM Do YYYY")
              }}
            </b-badge>
          </div>
          <div>
            <div class="py-25">
              <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
              <b-badge variant="light-secondary">
                {{
                  tripStep.className === 'TripFlight' && tripStep.flightDeparture ?
                    $moment(tripStep.flightDeparture.eventDateTime).utc().format("HH:mm") :
                    $moment(tripStep.transferDeparture.startsAt).utc().format("HH:mm")
                }}
              </b-badge>
            </div>
            <div v-if="tripStep.flightDeparture && tripStep.flightDeparture.airport" class="py-25">
              <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
              <b-badge variant="light-secondary">
                {{
                  $moment(tripStep.flightDeparture.eventDateTime).utcOffset(tripStep.flightDeparture.airport.timeZone).format("ddd, MMM Do YYYY HH:mm")
                }}
              </b-badge>
            </div>
          </div>
        </div>
      </section>
    </b-col>

    <!-- ///// ARRIVAL ///// -->
    <b-col
      :md="actionMode ? 3 : 4"
      class="border-right"
    >
      <section v-if="tripStep.flightArrival" class="p-50">
        <div class="d-flex align-items-center mb-50 font-weight-bold">
          <font-awesome-icon
            :icon="!tripStep.engineType ? 'plane-arrival' : 'helicopter'"
            class="mr-50"
            size="1x"
          />
          <span>
            {{ tripStepEventName(tripStep.flightArrival) }}
          </span>
        </div>

        <!--ADDRESS / SLOT-->
        <div>

          <!--AIRCRAFT EVENT-->
          <div v-if="!tripStep.engineType && tripStep.flightArrival.slotStatus">

            <span class="font-small-3 mr-50">{{ $t('trip.event.slot.title') }} : </span>
            <b-badge
              :variant="`${resolveSlotStatusVariant(tripStep.flightArrival.slotStatus)}`"
              class="font-small-3 mr-50"
            >
              {{ tripStep.flightArrival.slotStatus | enumTranslate('slot_status') }}
            </b-badge>
          </div>
        </div>
      </section>

      <section v-if="tripStep.transferArrival" class="p-50">
        <div class="d-flex align-items-center mb-50 font-weight-bold">
          <font-awesome-icon
            icon="helicopter"
            class="mr-50"
            size="1x"
          />
          <span>
            {{ tripTransferEventName(tripStep.transferArrival) }}
          </span>
        </div>
      </section>

      <section class="p-50">
        <div class="font-weight-bold d-flex align-items-center">
          <font-awesome-icon
            class="mr-50"
            icon="clock"
            size="1x"
          />
          <span class="font-weight-bold">{{ $t('trip.event.eta') }}</span>
        </div>
        <!--TIMEZONE-->
        <!--NO SLOT YET-->
        <div v-if="tripStep.className === 'TripTransfer' || (tripStep.flightArrival && !tripStep.flightArrival.eventDatetime)">
          <div class="py-25">
            <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
            <!-- TODO: refacto it -->
            <b-badge variant="light-secondary">{{
              tripStep.className === 'TripTransfer' ? $moment(tripStep.transferArrival.eventDateTime).format('ddd, MMM Do YYYY') : $moment(tripStep.flightArrival.eventDateTime).format('ddd, MMM Do YYYY')
            }}
            </b-badge>
          </div>
          <div>
            <div class="py-25">
              <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
              <b-badge variant="light-secondary">
                {{
                  tripStep.className === 'TripTransfer' ? $moment(tripStep.transferArrival.eventDateTime).utc().format('HH:mm') : $moment(tripStep.flightArrival.eventDateTime).utc().format('HH:mm')
                }}
              </b-badge>
            </div>
            <div v-if="tripStep.flightArrival && tripStep.flightArrival.airport" class="py-25">
              <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
              <b-badge variant="light-secondary">{{
                $moment(tripStep.flightArrival.eventDateTime).utcOffset(tripStep.flightArrival.airport.timeZone).format('ddd, MMM Do YYYY HH:mm')
              }}
              </b-badge>
            </div>
          </div>
        </div>
        <!--SLOT ADDED-->
        <div
          v-else-if="(tripStep.className === 'TripFlight' && tripStep.flightArrival) || (tripStep.className === 'TripTransfer' && tripStep.transferArrival)"
        >
          <div class="py-25">
            <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
            <b-badge variant="light-secondary">
              {{
                tripStep.className === 'TripFlight' ?
                  tripStep.flightArrival.eventDatetime :
                  tripStep.transferArrival.eventDatetime | moment("ddd, MMM Do YYYY")
              }}
            </b-badge>
          </div>
          <div>
            <div class="py-25">
              <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
              <b-badge variant="light-secondary">{{
                tripStep.className === 'TripFlight' ? $moment(tripStep.flightArrival.eventDatetime).utc().format("HH:mm") : $moment(tripStep.transferArrival.eventDatetime).utc().format("HH:mm")
              }}
              </b-badge>
            </div>
            <div v-if="tripStep.flightArrival && tripStep.flightArrival.airport" class="py-25">
              <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
              <b-badge variant="light-secondary">{{
                $moment(tripStep.flightArrival.eventDatetime).utcOffset(tripStep.flightArrival.airport.timeZone).format("ddd, MMM Do YYYY HH:mm")
              }}
              </b-badge>
            </div>
          </div>
        </div>
      </section>
    </b-col>

    <!-- ///// OTHER INFORMATIONS ///// -->
    <b-col
      :md="actionMode ? 3 : 4"
      class="d-flex justify-content-between flex-column"
    >
      <section v-if="tripStep.className === 'TripFlight'" class="p-50">
        <div v-if="tripStep.flightMission">
          <span class="font-weight-bold">{{ $t('trip.mission') }} : </span>
          <span>{{ tripStep.flightMission }}</span>
        </div>
        <div>
          <span
            v-if="tripStep.flightType"
            class="font-weight-bold"
          >{{ $t('trip.type.title') }} : </span>
          <span>{{ tripStep.flightType }}</span>
        </div>
      </section>

      <section class="p-50">
        <div>
          <div v-if="tripStep.className === 'TripFlight'" v-show="tripStep.crew !== undefined">
            <span class="font-weight-bold">{{ $tc('trip.event.crew_number', tripStep.crew) }} : </span>
            <span>{{ tripStep.crew }}</span>
          </div>
          <div v-show="tripStep.passengers !== undefined">
            <span class="font-weight-bold">{{ $tc('trip.event.passenger_number', tripStep.passengers) }} : </span>
            <span>{{ tripStep.passengers }}</span>
          </div>
        </div>
      </section>

      <section class="p-50">
        <div v-if="tripStep.className === 'TripFlight'">
          <b-overlay :show="flightPlanNumberLoading" rounded spinner-small spinner-variant="primary">
            <div class="d-flex justify-content-between plan-number">
              <div class="d-flex align-items-center flex-wrap">
                <span class="font-weight-bold mr-1">{{ $t('trip.flight_plan.number') }} : </span>
                <span v-if="flightPlanNumber && !$can('TRIP_FLIGHT_PLAN_EDIT')">{{ flightPlanNumber }}</span>
                <b-form-input
                  v-else
                  id="type-text"
                  v-model="flightPlanNumber"
                  :disabled="!$can('TRIP_FLIGHT_PLAN_EDIT')"
                  class="w-50"
                  size="sm"
                  type="text"
                ></b-form-input>
              </div>
              <b-button
                v-if="$can('TRIP_FLIGHT_PLAN_EDIT')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1 d-flex align-items-center justify-content-center"
                size="sm"
                variant="primary"
                @click="saveFlightPlanNumber(tripStep.id)"
              >
                <font-awesome-icon class="mr-50" icon="save" />
                {{ $t('action.save') }}
              </b-button>
            </div>
          </b-overlay>
          <div v-if="!tripStep.engineType">
            <span class="font-weight-bold">{{ $t('trip.flight_plan.status') }} : </span>
          <!--                En attente d'avoir les flight plan status-->
          </div>
        </div>

        <div v-if="tripStep.className === 'TripTransfer'">
          <span class="font-weight-bold">{{ $tc('trip.event.bag_number', tripStep.bags) }} : </span>
          <span>{{ tripStep.bags }}</span>
        </div>
        <div v-if="tripStep.className === 'TripTransfer'">
          <span class="font-weight-bold">{{ $tc('trip.event.luggage_number', tripStep.luggage) }} : </span>
          <span>{{ tripStep.luggage }}</span>
        </div>
        <div v-if="tripStep.className === 'TripTransfer'">
          <span class="font-weight-bold">{{ $tc('trip.helicopter.flying_time', tripStep.flyingTime) }} : </span>
          <span>{{ tripStep.flyingTime }} {{ $t('common.min') }}</span>
        </div>
      </section>
    </b-col>

    <b-col
      v-if="actionMode"
      class="d-flex flex-column justify-content-between text-center border-left"
      md="3"
    >
      <div v-if="tripStep.status">
        <b-badge
          :variant="`light-${resolveStatusVariant(tripStep.status)}`"
          class="mr-1"
        >
          {{ tripStep.status }}
        </b-badge>
      </div>

      <b-button
        v-if="$can('TRIP_VIEW')"
        class="d-flex align-items-center mr-50 mt-50 justify-content-center"
        size="sm"
        variant="outline-primary"
        @click="scrollIntoEvent(tripStep.id)"
      >
        <font-awesome-icon
          class="mr-50"
          icon="plus"
        />
        {{ $t('timeline.details.show') }}
      </b-button>
      <b-button
        v-if="$can('TRIP_DELETE')"
        class="mr-50 mb-50"
        variant="danger"
        @click="confirmDelete(tripStep)"
      >
        <font-awesome-icon
          class="mr-50"
          icon="ban"
        />
        {{ $t('action.cancel') }}
      </b-button>
    </b-col>
  </div>

  <div v-else>
    <section v-if="tripStepEvent.className === 'TripFlightDeparture' || tripStepEvent.className === 'TripFlightArrival'">
      <div
        id="trip-leg-event-resume-fbo"
        class="d-flex h-100"
      >
        <b-col
          :md="3"
        >
          <section class="p-50">
            <span
              v-if="tripStepEvent.className === 'TripFlightDeparture'"
              class="font-weight-bold d-flex align-items-center mb-50 "
            >
              {{ $t('trip.event.departure_to') }} :
            </span>
            <span
              v-else-if="tripStepEvent.className === 'TripFlightArrival'"
              class="font-weight-bold d-flex align-items-center mb-50 "
            >
              {{ $t('trip.event.arrival_from') }} :
            </span>
            <span v-if="tripStepEvent.airport" class="d-flex align-items-center mb-50 font-weight-bold h4">
              <font-awesome-icon
                v-if="tripStepEvent.className === 'TripFlightDeparture'"
                :icon="'plane-departure'"
                class="mr-50"
                size="1x"
              />
              <font-awesome-icon
                v-else-if="tripStepEvent.className === 'TripFlightArrival'"
                :icon="'plane-arrival'"
                class="mr-50"
                size="1x"
              />
              <span
                v-if="tripStepEvent.className === 'TripFlightDeparture' && tripStepEvent.tripStep.flightArrival.airport"
              >
                {{ tripStepEvent.tripStep.flightArrival.airport.icaoCode }}
              </span>
              <span
                v-if="tripStepEvent.className === 'TripFlightArrival' && tripStepEvent.tripStep.flightDeparture.airport"
              >
                {{ tripStepEvent.tripStep.flightDeparture.airport.icaoCode }}
              </span>
            </span>
            <span
              v-if="tripStepEvent.className === 'TripFlightDeparture' && tripStepEvent.tripStep.flightArrival.airport"
            >
              {{ tripStepEvent.tripStep.flightArrival.airport.name }}
            </span>
            <span
              v-if="tripStepEvent.className === 'TripFlightArrival' && tripStepEvent.tripStep.flightDeparture.airport"
            >
              {{ tripStepEvent.tripStep.flightDeparture.airport.name }}
            </span>
          </section>

          <!--TIME-->
          <!--TIME ETD-->
          <section v-if="tripStepEvent.className === 'TripFlightDeparture'" class="p-50">
            <div class="font-weight-bold d-flex align-items-center">
              <font-awesome-icon
                class="mr-50"
                icon="clock"
                size="1x"
              />
              <span class="font-weight-bold">{{ $t('trip.event.etd') }}</span>
            </div>
            <!--TIMEZONE-->
            <!--NO SLOT YET-->
            <div v-if="tripStep.engineType || (tripStep.flightDeparture && !tripStep.flightDeparture.startsAt)">
              <div class="py-25">
                <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
                <b-badge variant="light-secondary">
                  {{
                    !tripStep.engineType ? tripStep.flightDeparture.eventDateTime : tripStep.transferDeparture.eventDateTime | moment("ddd, MMM Do YYYY")
                  }}
                </b-badge>
              </div>
              <div>
                <div class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
                  <b-badge variant="light-secondary">
                    {{
                      !tripStep.engineType ? $moment(tripStep.flightDeparture.eventDateTime).utc().format('HH:mm') : $moment(tripStep.transferDeparture.eventDateTime).utc().format('HH:mm')
                    }}
                  </b-badge>
                </div>
                <div v-if="tripStep.flightDeparture && tripStep.flightDeparture.airport" class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
                  <b-badge variant="light-secondary">
                    {{
                      !tripStep.engineType ? $moment.utc(tripStep.flightDeparture.eventDateTime).utcOffset(tripStep.flightDeparture.airport.timeZone).format('ddd, MMM Do YYYY HH:mm') : $moment.utc(tripStep.transferDeparture.eventDateTime).local().format('HH:mm')
                    }}
                  </b-badge>
                </div>
              </div>
            </div>
            <!--SLOT ADDED-->
            <div
              v-else-if="(!tripStep.engineType && tripStep.flightDeparture) || (tripStep.engineType && tripStep.transferDeparture)"
            >
              <div class="py-25">
                <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
                <b-badge variant="light-secondary">
                  {{
                    !tripStep.engineType && tripStep.flightDeparture ?
                      tripStep.flightDeparture.startsAt :
                      tripStep.transferDeparture.startsAt | moment("ddd, MMM Do YYYY")
                  }}
                </b-badge>
              </div>
              <div>
                <div class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
                  <b-badge variant="light-secondary">
                    {{
                      !tripStep.engineType && tripStep.flightDeparture ?
                        $moment(tripStep.flightDeparture.eventDateTime).utc().format("HH:mm") :
                        $moment(tripStep.transferDeparture.startsAt).utc().format("HH:mm")
                    }}
                  </b-badge>
                </div>
                <div v-if="tripStep.flightDeparture && tripStep.flightDeparture.airport" class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
                  <b-badge variant="light-secondary">
                    {{
                      !tripStep.engineType && tripStep.flightDeparture ?
                        $moment(tripStep.flightDeparture.eventDateTime).utcOffset(tripStep.flightDeparture.airport.timeZone).format("ddd, MMM Do YYYY HH:mm") :
                        $moment(tripStep.transferDeparture.startsAt).local().format("HH:mm")
                    }}
                  </b-badge>
                </div>
              </div>
            </div>
          </section>
          <section v-if="tripStepEvent.className === 'TripFlightArrival'" class="p-50">
            <div class="font-weight-bold d-flex align-items-center">
              <font-awesome-icon
                class="mr-50"
                icon="clock"
                size="1x"
              />
              <span class="font-weight-bold">{{ $t('trip.event.eta') }}</span>
            </div>
            <!--TIMEZONE-->
            <!--NO SLOT YET-->
            <div v-if="tripStep.engineType || (tripStep.flightArrival && !tripStep.flightArrival.endsAt)">
              <div class="py-25">
                <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
                <!-- TODO: refacto it -->
                <b-badge variant="light-secondary">{{
                  !tripStep.engineType ? $moment(tripStep.flightArrival.eventDateTime).format('ddd, MMM Do YYYY') : $moment(tripStep.transferArrival.eventDateTime).format('ddd, MMM Do YYYY')
                }}
                </b-badge>
              </div>
              <div>
                <div class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
                  <b-badge variant="light-secondary">
                    {{
                      !tripStep.engineType ? $moment(tripStep.flightArrival.eventDateTime).utc().format('HH:mm') : $moment(tripStep.transferArrival.eventDateTime).utc().format('HH:mm')
                    }}
                  </b-badge>
                </div>
                <div v-if="tripStep.flightArrival && tripStep.flightArrival.airport" class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
                  <b-badge variant="light-secondary">{{
                    !tripStep.engineType ? $moment(tripStep.flightArrival.eventDateTime).utcOffset(tripStep.flightArrival.airport.timeZone).format('ddd, MMM Do YYYY HH:mm') : $moment(tripStep.transferArrival.eventDateTime).local().format('HH:mm')
                  }}
                  </b-badge>
                </div>
              </div>
            </div>
            <!--SLOT ADDED-->
            <div
              v-else-if="(!tripStep.engineType && tripStep.flightArrival) || (tripStep.engineType && tripStep.transferArrival)"
            >
              <div class="py-25">
                <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
                <b-badge variant="light-secondary">
                  {{
                    !tripStep.engineType ?
                      tripStep.flightArrival.endsAt :
                      tripStep.transferArrival.endsAt | moment("ddd, MMM Do YYYY")
                  }}
                </b-badge>
              </div>
              <div>
                <div class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
                  <b-badge variant="light-secondary">{{
                    !tripStep.engineType ? $moment(tripStep.flightArrival.endsAt).utc().format("HH:mm") : $moment(tripStep.transferArrival.endsAt).utc().format("HH:mm")
                  }}
                  </b-badge>
                </div>
                <div v-if="tripStep.flightArrival && tripStep.flightArrival.airport" class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
                  <b-badge variant="light-secondary">{{
                    !tripStep.engineType ? $moment(tripStep.flightArrival.endsAt).utcOffset(tripStep.flightArrival.airport.timeZone).format("ddd, MMM Do YYYY HH:mm") : $moment(tripStep.transferArrival.endsAt).local().format("HH:mm")
                  }}
                  </b-badge>
                </div>
              </div>
            </div>
          </section>

          <section class="p-50">
            <!--ADDRESS / SLOT-->
            <span>
              <div v-if="!tripStepEvent.tripStep.engineType">
                <span class="font-small-3 mr-50">{{ $t('trip.event.slot.title') }} : </span>
                <b-badge
                  v-if="tripStepEvent.slotStatus"
                  :variant="`${resolveSlotStatusVariant(tripStepEvent.slotStatus)}`"
                  class="font-small-3 mr-50"
                >
                  {{ tripStepEvent.slotStatus|enumTranslate('slot_status') }}
                </b-badge>
              </div>
            </span>
          </section>
        </b-col>

        <!-- ///// INFORMATIONS ///// -->
        <b-col
          :md="3"
          class="d-flex justify-content-start flex-column"
        >
          <section class="p-50">
            <div>
              <span
                v-if="tripStepEvent.tripStep.flightType"
                class="font-weight-bold"
              >{{ $t('trip.type.title') }} : </span>
              <span>{{ tripStepEvent.tripStep.flightType }}</span>
            </div>
            <div v-if="tripStepEvent.tripStep.flightMission">
              <span class="font-weight-bold">{{ $t('trip.mission') }} : </span>
              <span>{{ tripStepEvent.tripStep.flightMission }}</span>
            </div>
          </section>
          <section class="p-50">
            <div>
              <div v-show="tripStepEvent.tripStep.crew !== undefined">
                <span class="font-weight-bold">{{
                  $tc('trip.event.crew_number', tripStepEvent.tripStep.crew)
                }} : </span>
                <span>{{ tripStepEvent.tripStep.crew }}</span>
              </div>
              <div v-show="tripStepEvent.tripStep.passengers !== undefined">
                <span class="font-weight-bold">{{
                  $tc('trip.event.passenger_number', tripStepEvent.tripStep.passengers)
                }} : </span>
                <span>{{ tripStepEvent.tripStep.passengers }}</span>
              </div>
              <div v-show="tripStepEvent.tripStep.adults !== undefined">
                <span class="font-weight-bold">{{
                  $tc('trip.event.adult_number', tripStepEvent.tripStep.adults)
                }} : </span>
                <span>{{ tripStepEvent.tripStep.adults }}</span>
              </div>
              <div v-show="tripStepEvent.tripStep.children !== undefined">
                <span class="font-weight-bold">{{
                  $tc('trip.event.children_number', tripStepEvent.tripStep.children)
                }} : </span>
                <span>{{ tripStepEvent.tripStep.children }}</span>
              </div>
              <div v-show="tripStepEvent.tripStep.babies !== undefined">
                <span class="font-weight-bold">{{
                  $tc('trip.event.baby_number', tripStepEvent.tripStep.babies)
                }} : </span>
                <span>{{ tripStepEvent.tripStep.babies }}</span>
              </div>
            </div>
          </section>
        </b-col>

        <!-- ///// OTHER INFORMATIONS ///// -->
        <b-col
          :md="3"
          class="d-flex justify-content-start flex-column"
        >
          <section class="p-50">
            <div>
              <div v-show="tripStepEvent.tripStep.crew !== undefined">
                <span class="font-weight-bold">{{
                  $tc('trip.event.purchase_orders', tripStepEvent.tripStep.crew)
                }} : </span>
                <span>{{ tripStepEvent.purchaseOrdersNumber }}</span>
              </div>
            </div>
          </section>
          <section class="p-50">
            <div>
              <div
                v-for="category in tripStepEvent.categoryOrders"
                :key="category.id"
              >
                {{ category.name | trans }} ( {{ category.number }} )
              </div>
            </div>
          </section>
        </b-col>

        <b-col
          :md="3"
          class="d-flex justify-content-between flex-column"
        >
          <section class="p-50">
            <div>
              <b-overlay :show="flightPlanNumberLoading" rounded spinner-small spinner-variant="primary">
                <div class="d-flex justify-content-between plan-number">
                  <div class="d-flex align-items-center flex-wrap">
                    <span class="font-weight-bold mr-1">{{ $t('trip.flight_plan.number') }} : </span>
                    <span>{{ flightPlanNumber }}</span>
                  </div>
                  <b-button
                    v-if="$can('TRIP_FLIGHT_PLAN_EDIT')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-1 d-flex align-items-center justify-content-center"
                    size="sm"
                    variant="primary"
                    @click="saveFlightPlanNumber(tripStep.id)"
                  >
                    <font-awesome-icon class="mr-50" icon="save" />
                    {{ $t('action.save') }}
                  </b-button>
                </div>
              </b-overlay>
              <div>
                <span class="font-weight-bold">{{ $t('trip.flight_plan.status') }} : </span>
                <!--                En attente d'avoir les flight plan status-->
              </div>
            </div>
          </section>
          <section class="p-50">
            <b-button
              v-if="$can('TRIP_ACCOUNTING_VIEW')"
              :to="{ name: 'OrdersManager' }"
              class="d-flex align-items-center col-12 mt-50 justify-content-center"
              size="sm"
              variant="outline-primary"
            >
              <font-awesome-icon
                class="mr-50"
                icon="plus"
              />
              {{ $t('trip.event.view_orders') }}
            </b-button>
            <b-button
              v-if="actionMode"
              class="d-flex align-items-center col-12 mt-50 justify-content-center"
              size="sm"
              variant="outline-primary"
              @click="scrollIntoEvent(tripStepEvent.tripStep.id)"
            >
              <font-awesome-icon
                class="mr-50"
                icon="plus"
              />
              {{ $t('timeline.details.show') }}
            </b-button>
          </section>
        </b-col>
      </div>
    </section>

    <section v-if="tripStepEvent.className === 'TripTransferDeparture'">
      <div
        id="trip-leg-event-resume-fbo"
        class="d-flex h-100"
      >
        <!-- ///// DEPARTURE ///// -->
        <b-col
          :md="actionMode ? 3 : 4"
          class="border-right"
        >

          <section v-if="tripStepEvent" class="p-50 font-weight-bold">
            <span class="d-flex align-items-center mb-50">
              <font-awesome-icon
                icon="helicopter"
                class="mr-50"
                size="1x"
              />
              <span>
                {{ tripTransferEventName(tripStepEvent.tripStep.transferDeparture) }}
              </span>
            </span>
          </section>

          <!--TIME ETD-->
          <section class="p-50">
            <div class="font-weight-bold d-flex align-items-center">
              <font-awesome-icon
                class="mr-50"
                icon="clock"
                size="1x"
              />
              <span class="font-weight-bold">{{ $t('trip.event.etd') }}</span>
            </div>
            <!--TIMEZONE-->
            <!--NO SLOT YET-->
            <div>
              <div class="py-25">
                <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
                <b-badge variant="light-secondary">
                  {{ tripStepEvent.eventDateTime | moment("ddd, MMM Do YYYY") }}
                </b-badge>
              </div>
              <div>
                <div class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
                  <b-badge variant="light-secondary">
                    {{ $moment(tripStepEvent.eventDateTime).utc().format('HH:mm') }}
                  </b-badge>
                </div>
              </div>
            </div>
          </section>
        </b-col>

        <!-- ///// ARRIVAL ///// -->
        <b-col
          v-if="tripStepEvent.tripStep.transferArrival"
          :md="actionMode ? 3 : 4"
          class="border-right"
        >
          <section class="p-50">
            <div class="d-flex align-items-center mb-50 font-weight-bold">
              <font-awesome-icon
                icon="helicopter"
                class="mr-50"
                size="1x"
              />
              <span>
                {{ tripTransferEventName(tripStepEvent.tripStep.transferArrival) }}
              </span>
            </div>
          </section>

          <section class="p-50">
            <div class="font-weight-bold d-flex align-items-center">
              <font-awesome-icon
                class="mr-50"
                icon="clock"
                size="1x"
              />
              <span class="font-weight-bold">{{ $t('trip.event.eta') }}</span>
            </div>
            <!--TIMEZONE-->
            <!--NO SLOT YET-->
            <div>
              <div class="py-25">
                <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
                <!-- TODO: refacto it -->
                <b-badge variant="light-secondary">
                  {{ $moment(tripStepEvent.tripStep.transferArrival.eventDateTime).format('ddd, MMM Do YYYY') }}
                </b-badge>
              </div>
              <div>
                <div class="py-25">
                  <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
                  <b-badge variant="light-secondary">
                    {{ $moment(tripStepEvent.tripStep.transferArrival.eventDateTime).utc().format('HH:mm') }}
                  </b-badge>
                </div>
              </div>
            </div>
          </section>
        </b-col>

        <!-- ///// OTHER INFORMATIONS ///// -->
        <b-col
          :md="actionMode ? 3 : 4"
          class="d-flex justify-content-between flex-column"
        >
          <section class="p-50">
            <div>
              <div v-show="tripStepEvent.tripStep.passengers !== undefined">
                <span class="font-weight-bold">{{ $tc('trip.event.passenger_number', tripStepEvent.tripStep.passengers) }} : </span>
                <span>{{ tripStepEvent.tripStep.passengers }}</span>
              </div>
            </div>
          </section>

          <section class="p-50">
            <div>
              <span class="font-weight-bold">{{ $tc('trip.event.bag_number', tripStepEvent.tripStep.bags) }} : </span>
              <span>{{ tripStepEvent.tripStep.bags }}</span>
            </div>
            <div>
              <span class="font-weight-bold">{{ $tc('trip.event.luggage_number', tripStepEvent.tripStep.luggage) }} : </span>
              <span>{{ tripStepEvent.tripStep.luggage }}</span>
            </div>
            <div>
              <span class="font-weight-bold">{{ $tc('trip.helicopter.flying_time', tripStepEvent.tripStep.flyingTime) }} : </span>
              <span>{{ tripStepEvent.tripStep.flyingTime }} {{ $t('common.min') }}</span>
            </div>
          </section>
        </b-col>

        <b-col
          v-if="actionMode"
          class="d-flex flex-column justify-content-between text-center border-left"
          md="3"
        >
          <div v-if="tripStepEvent.tripStep.status">
            <b-badge
              :variant="`light-${resolveStatusVariant(tripStepEvent.tripStep.status)}`"
              class="mr-1"
            >
              {{ tripStepEvent.tripStep.status }}
            </b-badge>
          </div>

          <b-button
            v-if="$can('TRIP_VIEW')"
            class="d-flex align-items-center mr-50 mt-50 justify-content-center"
            size="sm"
            variant="outline-primary"
            @click="scrollIntoEvent(tripStepEvent.tripStep.id)"
          >
            <font-awesome-icon
              class="mr-50"
              icon="plus"
            />
            {{ $t('timeline.details.show') }}
          </b-button>
          <b-button
            v-if="$can('TRIP_DELETE')"
            class="mr-50 mb-50"
            variant="danger"
            @click="confirmDelete(tripStepEvent.tripStep)"
          >
            <font-awesome-icon
              class="mr-50"
              icon="ban"
            />
            {{ $t('action.cancel') }}
          </b-button>
        </b-col>
      </div>
    </section>
  </div>
</template>
<script>

import UIHelper from '@/helpers/ui'
import TripHelper from '@/helpers/trip'
import AccessControl from '@/helpers/accessControl'
import { deleteTripStepRequest, patchTripStepFlightPlanRequest } from '@/request/globalApi/requests/tripStepRequests'
import { mapState } from 'vuex'

export default {
  name: 'TripLegResume',
  mixins: [UIHelper, AccessControl, TripHelper],
  props: {
    actionMode: {
      type: Boolean,
      default: true,
    },
    tripStep: {
      type: [Object, Array],
      default: () => ({}),
    },
    tripStepEvent: {
      type: [Object, Array],
      default: () => ({}),
    },
    tripData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      flightPlanNumber: null,
      flightPlanNumberLoading: false,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  mounted() {
    if (this.tripStep && this.tripStep.flightPlanNumber) {
      this.flightPlanNumber = this.tripStep.flightPlanNumber
    } else if (this.tripStepEvent && this.tripStepEvent.tripStep && this.tripStepEvent.tripStep.flightPlanNumber) {
      this.flightPlanNumber = this.tripStepEvent.tripStep.flightPlanNumber
    }
  },
  methods: {
    saveFlightPlanNumber(tripStepId) {
      this.flightPlanNumberLoading = true
      const flightPlanNumber = {
        flightPlanNumber: this.flightPlanNumber,
      }
      patchTripStepFlightPlanRequest(tripStepId, flightPlanNumber)
        .finally(() => {
          this.flightPlanNumberLoading = false
        })
    },
    confirmDelete(tripStep) {
      deleteTripStepRequest(tripStep.id)
        .then(() => {
          this.$emit('refetch-data')
        })
    },
    scrollIntoEvent(eventId) {
      const el = document.getElementById(`event-${eventId}`)
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#trip-leg-resume, #trip-leg-resume-fbo {
  overflow: auto;

  .plan-number {
    @media screen and (max-width: 1600px) {
      flex-direction: column;
      button {
        margin: 8px 0 15px 0 !important;
      }
    }
    @media screen and (max-width: 1000px) {
      > div, button, input {
        width: 100% !important;
      }
    }
  }
}
</style>
