import { can } from '@core/libs/acl/utils'
import store from '@/store/store'

export default {
  methods: {
    checkOrganizationAccess(action, organizationId) {
      const { userData } = store.state.auth
      const findOrganization = userData.organizations.some(
        organisation => organisation.id === organizationId,
      )
      return can(action) && findOrganization
    },
  },
}
