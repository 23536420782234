import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/airport'

export const fetchAirportsRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchAirportRequest = id => globalApi.get(`${END_POINT}/${id}`)

export const postAirportRequest = (airport, message) => globalApi.post(END_POINT, airport, { message })

export const patchAirportRequest = (airport, message) => globalApi.patch(`${END_POINT}/${airport.id}`, airport, { message })

export const deleteAirportRequest = (id, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}`, { message }))
