<template>
  <b-collapse
    :id="'cost-details_'+tripStep.id"
    :ref="'cost-details_'+tripStep.id"
  >
    <b-card class="border rounded">
      <b-card-title class="mb-0">
        {{ $t('trip.cost.details') }}
      </b-card-title>
      <b-alert
        v-if="!charges"
        class="mt-1"
        show
        variant="primary"
      >
        <div class="alert-body">
          <span>{{ $t('alert.trip.no_airport_charges_informations') }}</span>
        </div>
      </b-alert>
      <b-table-simple
        v-else
        bordered
        caption-top
        hover
        responsive
        small
      >
        <caption> {{ $t('trip.cost.estimated_airport_charge') }} :</caption>

        <b-thead head-variant="light">
          <b-tr>
            <b-th colspan="1">
              Billing
            </b-th>
            <b-th colspan="1">
              Categorie
            </b-th>
            <b-th colspan="1">
              Element
            </b-th>
            <b-th colspan="1">
              <font-awesome-icon icon="plane-departure" />
              {{ tripStep.flightDeparture.address.addressName }}
            </b-th>
            <b-th colspan="1">
              <font-awesome-icon icon="plane-arrival" />

              {{ tripStep.flightArrival.address.addressName }}
            </b-th>
            <b-th colspan="1">
              Total leg
            </b-th>
          </b-tr>
        </b-thead>

        <b-tbody
          v-for="(templateMaster, indexMaster) in templateFormat"
          :key="indexMaster + '_' + tripStep.id"
        >
          <b-tr
            v-for="(template, indexTemplate) in templateMaster[0]"
            :key="indexTemplate + '_' + indexMaster + '_' + tripStep.id"
          >
            <b-th
              v-for="(thead, indexHead) in template.tr[0].th"
              :key="indexHead + '_head_' + indexTemplate + '_' + indexMaster + '_' + tripStep.id"
              :rowspan="thead.rowspan"
              class="p-50"
            >
              {{ thead.name }}
            </b-th>
            <b-td
              v-for="(cell, indexCell) in template.tr[0].td"
              :key="indexCell + '_cell_' + indexTemplate + '_' + indexMaster + '_' + tripStep.id"
            >
              <span v-if="cell === 0">-</span>
              <span v-else>
                {{ Number(cell).toLocaleString($i18n.locale, { minimumFractionDigits: 2 }) }} {{ $store.state.appConfig.currency }}
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td
              v-if="originTotalPrice"
              class="text-right font-medium-1"
              variant="secondary"
            >
              <span>{{ $t('trip.cost.total_before_tax') }}: </span> <b class="mr-2"> {{ originTotalPrice.toLocaleString('FR-fr', { minimumFractionDigits: 2 }) }} {{ $store.state.appConfig.currency }}</b>
            </b-td>
            <b-td
              v-if="destinationTotalPrice"
              class="text-right font-medium-1"
              variant="secondary"
            >
              <span>{{ $t('trip.cost.total_before_tax') }}: </span> <b class="mr-2"> {{ destinationTotalPrice | priceFormat }}</b>
            </b-td>
            <b-td
              v-if="totalPrice"
              class="text-right font-medium-1"
              variant="secondary"
            >
              <span>{{ $t('trip.cost.total_before_tax') }}: </span> <b class="mr-2"> {{ totalPrice || priceFormat }}</b>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>

    </b-card>
  </b-collapse>
</template>
<script>
import { fetchTripStepCostDetailsRequest } from '@/request/globalApi/requests/tripStepRequests'

export default {
  name: 'TripLegCostDetails',

  props: {
    tripStep: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      charges: null,
      destinationTotalPrice: null,
      originTotalPrice: null,
      totalPrice: null,
      templateFormat: [],
    }
  },
  mounted() {
    // TODO: too requests are run unnecessary, bad performance, so improve it
    // this.$watch(
    //   () => this.$refs[`cost-details_${this.tripStep.id}`].show,
    //   val => {
    // if (val) {
    fetchTripStepCostDetailsRequest(this.tripStep.id)
      .then(r => {
        this.charges = !r.data.charges || r.data.charges.length !== undefined ? null : r.data.charges
        if (this.charges) {
          this.destinationTotalPrice = r.data.destinationTotalPrice
          this.originTotalPrice = r.data.originTotalPrice
          this.totalPrice = r.data.totalPrice
          this.costArrayFormat()
        }
        this.$emit('update:charges', this.charges)
      })
    // }
      // },
    // )
  },
  methods: {
    costArrayFormat() {
      this.templateFormat = []
      Object.entries(this.charges).forEach(chargeData => {
        const [chargeName, charge] = chargeData
        const currentTemplate = []
        let categoryIndex = 0
        Object.entries(charge).forEach(categoryData => {
          const [categoryName, category] = categoryData
          let elementIndex = 0
          Object.entries(category).forEach(elementData => {
            const [elementName, element] = elementData
            let template
            if (categoryIndex++ === 0) {
              elementIndex++
              template = {
                tr: [{
                  th: [
                    { name: chargeName, rowspan: this.calRowSpan2(charge) },
                    { name: categoryName, rowspan: Object.keys(category).length },
                    { name: elementName, rowspan: 1 },
                  ],
                  td: [element.originPrice, element.destinationPrice, element.totalPrice],
                }],
              }
            } else if (elementIndex++ === 0) {
              template = {
                tr: [{
                  th: [
                    { name: categoryName, rowspan: Object.keys(category).length },
                    { name: elementName, rowspan: 1 },
                  ],
                  td: [element.originPrice, element.destinationPrice, element.totalPrice],
                }],
              }
            } else {
              template = {
                tr: [{
                  th: [
                    { name: elementName, rowspan: 1 },
                  ],
                  td: [element.originPrice, element.destinationPrice, element.totalPrice],
                }],
              }
            }

            currentTemplate.push(template)
          })
        })

        this.templateFormat.push([currentTemplate])
      })
    },
    calRowSpan2(charge) {
      let { length } = Object.keys(charge)
      Object.values(charge).forEach(category => {
        length += Object.keys(category).length
      })

      return length
    },
  },
}
</script>
