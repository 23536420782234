<template>
  <div id="timeline-step-flight-ppr">
    <b-overlay :show="pprPdfLoading">
      <b-button v-ripple.400 class="mr-1" size="sm" @click.prevent="generateReport()">
        <font-awesome-icon class="mr-50" icon="download" />
        {{ $t('trip.event.slot.ppr') }}
      </b-button>
    </b-overlay>
    <vue-html2pdf
      v-if="pprPdfLoading"
      ref="ppr-pdf"
      :enable-download="false"
      :float-layout="true"
      :manual-pagination="false"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :preview-modal="false"
      :show-layout="false"
      filename="ppr"
      pdf-content-width="100%"
      pdf-format="a4"
      pdf-orientation="portrait"
      @beforeDownload="beforeDownload($event)"
    >
      <section slot="pdf-content">
        <b-card-body v-if="ppr && ppr.trip">
          <div class="d-flex justify-content-start align-items-center">
            <h1 class="mb-0 ppr_title">{{ $t('trip.event.slot.ppr') }}</h1>
          </div>
          <div class="ppr_infos">
            <div class="info">
              <strong>{{ $t('trip.event.slot.ppr_date') }} : </strong>
              <span v-if="stepType === 'departure'">{{ $moment.utc(ppr.flightArrival.pprRequestDate).format('ddd, MMM Do YYYY - HH:mm') }} {{ $t('common.utc') }}</span>
              <span v-else>{{ $moment.utc(ppr.flightDeparture.pprRequestDate).format('ddd, MMM Do YYYY - HH:mm') }} {{ $t('common.utc') }}</span>
            </div>

            <div class="info">
              <strong>
                {{ $t('common.airport') }}:
              </strong>
              <span v-if="stepEvent.airport"> {{ stepEvent.airport.name }} - {{ stepEvent.airport.icaoCode }}</span>
            </div>

            <div class="specific">
              <strong>
                {{ $t('trip.event.movement') }}:
              </strong>
              {{ stepType === 'departure' ? $t('trip.departure') : $t('trip.arrival') }}
            </div>

            <div class="info">
              <strong>{{ $t('common.date') }} : </strong>
              <span v-if="stepEvent.slotStartDate">{{ $moment.utc(stepEvent.slotStartDate).format('ddd, MMM Do YYYY') }} {{ $t('common.utc') }}</span>
              <span v-else>{{ $moment.utc(stepEvent.eventDateTime).format('ddd, MMM Do YYYY') }} {{ $t('common.utc') }}</span>
            </div>

            <div class="info">
              <strong>{{ $t('trip.event.slot.ppr_requested_slot') }} : </strong>
              <span v-if="stepEvent.slotStartDate && stepEvent.slotEndDate">{{ $moment.utc(stepEvent.slotStartDate).format('HH:mm') }} - {{ $moment.utc(stepEvent.slotEndDate).format('HH:mm') }} {{ $t('common.utc') }}</span>
              <span v-else>{{ $moment.utc(stepEvent.startsAt).format('HH:mm') }} - {{ $moment.utc(stepEvent.endsAt).format('HH:mm') }} {{ $t('common.utc') }}</span>
            </div>

            <div class="specific">
              <strong>{{ $t('aircraft.call_sign') }} : </strong><span v-if="ppr.trip.organizationAircraft.callSign">{{ ppr.trip.organizationAircraft.callSign }}</span>
            </div>

            <div class="info">
              <strong>{{ $t('aircraft.registration') }} : </strong><span v-if="ppr.trip.organizationAircraft.registration">{{ ppr.trip.organizationAircraft.registration }}</span>
            </div>

            <div class="info">
              <strong>{{ $t('airport.icao') }} : </strong><span v-if="ppr.trip.organizationAircraft">{{ ppr.trip.organizationAircraft.type }}</span>
            </div>

            <div class="info">
              <strong>{{ $t('trip.event.slot.ppr_aircraft') }} : </strong><span>{{ ppr.trip.organizationAircraft && ppr.trip.organizationAircraft.homeBase ? ppr.trip.organizationAircraft.homeBase.name : '' }}</span>
            </div>

            <div v-if="stepType === 'departure'" class="specific">
              <div class="info">
                <strong class="text-capitalize">{{ $t('common.to') }} : </strong><span>{{ ppr.flightArrival.airport.name }} - {{ ppr.flightArrival.airport.icaoCode }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.eta') }} : </strong><span>{{ $moment.utc(ppr.flightArrival.eventDateTime).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.slot.ppr_pax') }} : </strong><span>{{ ppr.passengers }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.slot.ppr_crew') }} : </strong><span>{{ ppr.crew }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.flight_plan.number') }} : </strong><span>{{ ppr.flightPlanNumber }}</span>
              </div>
            </div>

            <div v-else class="specific">
              <div class="info">
                <strong class="text-capitalize">{{ $t('common.from') }} : </strong><span>{{ ppr.flightDeparture.airport.name }} - {{ ppr.flightDeparture.airport.icaoCode }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.etd') }} : </strong><span>{{ $moment.utc(ppr.flightDeparture.eventDateTime).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.slot.ppr_pax') }} : </strong><span>{{ ppr.passengers }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.event.slot.ppr_crew') }} : </strong><span>{{ ppr.crew }}</span>
              </div>

              <div class="info">
                <strong>{{ $t('trip.flight_plan.number') }} : </strong><span>{{ ppr.flightPlanNumber }}</span>
              </div>
            </div>
          </div>
        </b-card-body>
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import { fetchTripStepPprRequest } from '@/request/globalApi/requests/tripStepRequests'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'TimelineStepFlightPpr',

  components: {
    VueHtml2pdf,
  },

  props: {
    stepTrip: {
      type: Object,
      default: () => ({}),
    },
    stepEvent: {
      type: Object,
      default: () => ({}),
    },
    stepType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      ppr: {},
      pprPdfLoading: false,
    }
  },

  methods: {
    generateReport() {
      this.pprPdfLoading = true
      fetchTripStepPprRequest(this.stepTrip.id)
        .then(response => {
          this.ppr = response.data
        })
        .then(() => {
          this.$refs['ppr-pdf'].generatePdf()
        })
        .catch(err => {
          err && (this.pprPdfLoading = false)
        })
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
            pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() * 0.88, pdf.internal.pageSize.getHeight() - 0.3)
            pdf.setProperties({
              title: this.flightPlanNumber || '',
              subject: this.trip?.organizationAircraft?.name || '',
              author: this.flightPlanNumber || '',
              keywords: `COPYRIGHT  © ${new Date().getFullYear()} MyFlight Connect, ${this.$t('footer.all_rights_reserved')}` || '',
              creator: 'MyFlight Connect',
            })
          }
          window.open(pdf.output('bloburl'), '_blank')
        })
        .then(() => {
          this.pprPdfLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.ppr_infos {
  .info {
    margin: 10px 0;
  }
  .specific {
    margin-top: 40px;
  }
}
.ppr_title {
  padding: 20px;
  text-align: center;
}
</style>
