import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/crew-member'

// MAIN
export const fetchCrewMembersRequest = () => globalApi.get(`${END_POINT}`)

export const postCrewMemberRequest = (crewMember, message) => globalApi.post(`${END_POINT}`, crewMember, { message })

export const fetchCrewMemberRequest = crewMemberId => globalApi.get(`${END_POINT}/${crewMemberId}`)

export const editCrewMemberRequest = (crewMember, message) => globalApi.patch(`${END_POINT}/${crewMember.id}`, crewMember, { message })

export const deleteCrewMemberRequest = (crewMemberId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${crewMemberId}`, { message }),
)
