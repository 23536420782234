<template>
  <div>
    <section
      v-if="(selectedOrganization.id === tripData.organization.id || selectedOrganization.operator) && tripLegEstimatedCost"
    >
      <b-card-header class="d-flex justify-content-start pt-0 px-0">
        <div class="font-weight-bold">
          {{ $t('trip.total_legs.estimated_cost') }} :
        </div>
      </b-card-header>
      <div v-if="tripData.tripType !== 'transfer'">
        <span class="font-weight-bold">{{ $t('trip.total_legs.airport_charges') }}</span> :
        <span>{{ tripLegEstimatedCost.airportCharges | priceFormat }}</span>
      </div>
      <div>
        <span class="font-weight-bold">{{ $t('trip.total_legs.excluding_taxes') }}</span> :
        <span>{{ tripLegEstimatedCost.stepTotalOrdersBeforeTaxes | priceFormat }}</span>
      </div>
      <div>
        <span class="font-weight-bold">{{ $t('trip.total_legs.including_taxes') }}</span> :
        <span>{{ tripLegEstimatedCost.stepTotalOrdersIncludingTaxes | priceFormat }} </span>
      </div>
      <b-button
        v-if="tripStep.className === 'TripFlight'"
        v-b-toggle="'cost-details_'+tripStep.id"
        block
        class="mt-50"
        size="sm"
        variant="outline-primary"
        :disabled="disabled"
        @click="isCollapsed = !isCollapsed"
      >
        {{ !isCollapsed ? $t('trip.cost.view_details') : $t('trip.cost.hide_details') }}
      </b-button>
    </section>
    <section v-else>
      <b-card-header class="d-flex justify-content-start pt-0 px-0">
        <div class="font-weight-bold">
          {{ $t('trip.total_legs.estimated_cost') }} :
        </div>
      </b-card-header>
      <div>
        <span class="font-weight-bold">{{ $t('trip.total_legs.excluding_taxes') }}</span> :
        <span>{{ tripStepEvent.eventTotalOrdersBeforeTaxes | priceFormat }}</span>
      </div>
      <div>
        <span class="font-weight-bold">{{ $t('trip.total_legs.including_taxes') }}</span> :
        <span>{{ tripStepEvent.eventTotalOrdersIncludingTaxes | priceFormat }} </span>
      </div>
      <b-button
        v-if="selectedOrganization.id === tripData.organization.id || selectedOrganization.operator"
        v-b-toggle="'cost-details_'+tripStep.id"
        block
        class="mt-50"
        size="sm"
        variant="outline-primary"
        @click="isCollapsed = !isCollapsed"
      >
        {{ !isCollapsed ? $t('trip.cost.view_details') : $t('trip.cost.hide_details') }}
      </b-button>
    </section>
  </div>
</template>
<script>

import { mapState } from 'vuex'
import { fetchTripInfosRequest } from '@/request/globalApi/requests/tripRequest'

export default {
  name: 'TripLegCost',
  props: {
    tripStep: {
      type: Object,
      default: () => ({}),
    },
    tripStepEvent: {
      type: Object,
      default: () => ({}),
    },
    tripData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCollapsed: false,
      trip: null,
      tripLegEstimatedCost: {},
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  mounted() {
    this.APIFetchTripInfos()
  },
  methods: {
    APIFetchTripInfos() {
      fetchTripInfosRequest(this.tripData.id)
        .then(response => {
          const { trip } = response.data
          this.trip = trip
          this.tripLegEstimatedCost = this.trip.tripSteps.find(tripStep => tripStep.id === this.tripStep.id)
        })
    },
  },
}
</script>
